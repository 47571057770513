import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import { patternNumber } from '../../../common/validatePhoneNumber';
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';

export default function FormAddEditZionArea(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        onSubmitAddEdit, department } = props;
    const buttonSubmitRef = useRef(null);

    const [checkActive, setCheckActive] = useState(true);
    useEffect(() => {
        setCheckActive(updateItem?.isActived);
        setValue("name", updateItem?.name);
        setValue("memberCount", updateItem?.memberCount);
        setValue("description", updateItem?.description);
        setValue("departmentId", updateItem?.departmentId);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }
        
        onSubmitAddEdit({...data, id: updateItem?.id});
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Tên khu vực <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={updateItem?.name}
                        maxLength="150"
                        placeholder="Nhập tên"
                        ref={register({ required: true, maxLength:150 })}
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.name && (errors.name.type === "maxLength") && (
                        <span className="error">Độ dài không vượt quá 150 ký tự</span>
                    )}
                </div>
                <div className="form-group col-md-4">
                    <label>Số lượng t/đồ <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="memberCount"
                        defaultValue={updateItem?.memberCount || null}
                        placeholder="Số lượng thánh đồ"
                        ref={register({
                            required: true,
                            validate: (value) => {
                                if (parseInt(value) <= 0) {
                                    return "invalidMemberCount";
                                }
                                return true;
                            }
                        })}
                        onChange={(e) =>
                            setValue(
                                "memberCount",
                                e.target.value.replace(patternNumber, "")
                            )
                        }
                    />
                    {errors.memberCount && errors.memberCount.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.memberCount && errors.memberCount.type === "validate" && (
                        <span className="error">Giá trị không hợp lệ</span>
                    )}
                </div>
                <div className="form-group col-md-4">
                    <label>Thuộc địa vực <span className='required'>*</span></label>
                    <DepartmentTreeSelect
                        {...register("departmentId", { required: true })}
                        onChange={(data) => setValue("departmentId", data ? data.value : null)}
                        selected={updateItem?.departmentId ? { label: updateItem?.departmentName, value: updateItem?.departmentId } : null}
                        placeholder="Chọn địa vực"
                        options={department}
                    />
                    {errors.departmentId && errors.departmentId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                
            </div>
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Ghi chú</label>
                    <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={updateItem?.description}
                        maxLength="500"
                        placeholder="Ghi chú"
                        ref={register({ maxLength: 500 })}
                    />
                    {errors.description && (errors.description.type === "maxLength") && (
                        <span className="error">Độ dài không vượt quá 500 ký tự</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Trạng thái hoạt động</label>
                    <div className="switch-button switch-button-yesno ml-5">
                        <input
                            type="checkbox"
                            checked={checkActive}
                            name="isActived"
                            id="isActived"
                            ref={register()}
                            onChange={() => setCheckActive(prev => !prev)}
                        />
                        <span><label htmlFor="isActived"></label></span>
                    </div>
                </div>
            </div>
            
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}