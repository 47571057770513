import React, { useState, useEffect } from 'react';
import Popover from '@mui/material/Popover';
import { getUserInfo } from "../../../utils/configuration";

const DepartmentTreeTableContent = (props) => {
    const {
        data,
        originData,
        setOpenDeactiveDialog,
        setOpenActiveDialog,
        setOpenDeleteDialog,
        openConfirmDialog, openEditDialog,
        customer, getDepartmentCustomerTalang, getDepartmentCustomerBaptized
    } = props;

    const [userInfo, setUserInfo] = useState(getUserInfo());
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const toggleCollapse = (itemId) => {
        const collapseElement = document.getElementById(`control-collapse${itemId}`);
        if (collapseElement) {
            setTimeout(() => {
                collapseElement.click();
            }, 10);
        }
    };
    
    const showCusClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCusClose = () => {
        setAnchorEl(null);
    };

    const renderRows = (rowData) => {
        return rowData.map((item, index) => {
            let hasChildren = item.children && item.children.length > 0;
            
            return (
                <React.Fragment key={index}>
                    <tr
                        className={`${hasChildren ? 'row_have_children' : ''}`}
                        {...(hasChildren ? (
                            {
                                "id": `control-collapse${item.id}`,
                                "data-toggle": "collapse",
                                "data-target": `.collapse${item.id}`,
                                "aria-expanded": "false",
                                "aria-controls": `collapse${item.id}`
                            }
                        ) : {})}
                    >
                        <td style={{ paddingLeft: item.level * 20 + 'px' }}>
                            {
                                hasChildren ? <span className="fas fa-angle-down mr-3" /> : null
                            }
                            <span style={{ marginLeft: hasChildren ? null : "30px" }}>{item.name}</span>
                        </td>
                        <td>
                            <span>{item.description}</span>
                        </td>
                        <td>
                            <span>{item.memberCount}</span>
                        </td>
                        {/*<td onClick={(event) => {*/}
                        {/*    if (item.talentAmount > 0) {*/}
                        {/*        getDepartmentCustomerTalang(item.id);*/}
                        {/*        showCusClick(event);*/}
                        {/*    }*/}
                        {/*}}>*/}
                        {/*    {item.talentAmount > 0 ? <span style={{ color: 'blue', cursor: 'pointer' }}>*/}
                        {/*        {item.talentAmount} </span> : <></>*/}
                        {/*    }*/}
                        {/*</td>*/}
                        {/*<td onClick={(event) => {*/}
                        {/*    if (item.talentBaptemAmount > 0) {*/}
                        {/*        getDepartmentCustomerBaptized(item.id);*/}
                        {/*        showCusClick(event);*/}
                        {/*    }*/}
                        {/*}}>*/}
                        {/*    {item.talentBaptemAmount > 0 ? <span style={{ color: 'blue', cursor: 'pointer' }}>*/}
                        {/*        {item.talentBaptemAmount} </span> : <></>*/}
                        {/*    }*/}
                        {/*</td>*/}
                        {/*<td>*/}
                        {/*    <span>{item.currentRank}</span>*/}
                        {/*</td>*/}
                        <td align="center">
                            <span>
                                {item.isActived ? (
                                    <img
                                        title="Đang kích hoạt"
                                        style={{ cursor: 'pointer' }}
                                        src={require("../../../assets/icon/tick.png")}
                                        alt="Tick"
                                        onClick={() => {
                                            if (hasChildren) toggleCollapse(item.id);
                                            if (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN"))) {
                                                setOpenDeactiveDialog(true);
                                                openConfirmDialog(item);
                                            }
                                        }}
                                    />
                                ) : (
                                    <img
                                        title="Ngưng kích hoạt"
                                        style={{ cursor: 'pointer' }}
                                        src={require("../../../assets/icon/cancel.png")}
                                        alt="Cancel"
                                        onClick={() => {
                                            if (hasChildren) toggleCollapse(item.id);
                                            if (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN"))) {
                                                setOpenActiveDialog(true);
                                                openConfirmDialog(item);
                                            }
                                            
                                        }}
                                    />
                                )}
                            </span>
                        </td>
                        <td>
                            <div className='d-flex'>
                                <button
                                    className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Sửa"
                                    onClick={(e) => {
                                        if (hasChildren) toggleCollapse(item.id);
                                        openEditDialog(originData.find(x=>x.id=== item.id));
                                    }}
                                >
                                    <i className="far fa-edit"></i>
                                </button>
                                {
                                    (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN"))) && 
                                    <button
                                        className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Xóa"
                                        onClick={() => {
                                            if (hasChildren) toggleCollapse(item.id);
                                            setOpenDeleteDialog(true);
                                            openConfirmDialog(item);
                                        }}
                                    >
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                }
                                
                            </div>
                        </td>
                    </tr>
                    {
                        item.children && item.children.length > 0 &&
                        <tr
                            id={`collapse${item.id}`}
                            className={`_row_child_wrapper collapse collapse${item.id}`}
                            aria-labelledby={`heading${item.id}`}
                            data-parent="#aa"
                        >
                            {renderRows(item.children)}
                        </tr>
                    }
                </React.Fragment>
            );
        });
    };

    return (
        <>
        <Popover
                id='showCus'
                open={open}
                anchorEl={anchorEl}
                onClose={handleCusClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                    {
                        customer && customer.length > 0 && customer.filter(x => x.status >= 50).length > 0 &&
                        <table className="table table-bordered table-hover dataTable" id="datatables">
                                <thead>
                                    <tr>
                                        <th><b>STT</b></th>
                                        <th><b>Họ và tên</b></th>
                                        <th><b>Giới tính</b></th>
                                        <th><b>Độ tuổi</b></th>
                                        <th><b>Hạng mục</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        customer && customer.length > 0 ?
                                            customer.filter(x => x.status >=50).map((row, rowIndex) =>
                                            (
                                                <tr key={rowIndex}>
                                                    <td>{rowIndex + 1}</td>
                                                    <td>{row.fullName}
                                                        {
                                                            row.customerType==1 ? " (La-xa-rơ)" : ""
                                                        }
                                                    </td>
                                                    <td>{row.gender == 0 ? "Nữ" : "Nam"}</td>
                                                    <td>{row.departmentName}</td>
                                                    <td>{row.statusName}</td>
                                                </tr>
                                            )) : <tr><td colSpan={5}>Chưa có dữ liệu</td></tr>
                                    }
                                </tbody>
                        </table>
                    }
            </Popover>
            <tbody className='custom-tree-table'>
                {data.length > 0 ? (
                    renderRows(data.filter(item => !item.parentId))
                ) : (
                    <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                )}
            </tbody>
        </>
        
    );
};

export default DepartmentTreeTableContent;