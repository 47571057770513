import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import Select from "react-select";
import DepartmentTreeSelect from '../../list-department/components/department-tree-select';

export default function FormAddEditHerdsman(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        group, department, zion, zionArea, herdsman, district, position, wMTeam,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    const [groupSelected1, setGroupSelected1] = useState(0);
    const [groupSelected2, setGroupSelected2] = useState(0);
    const [checkActive, setCheckActive] = useState(true);
    const [herdsmanType, setHerdsmanType] = useState();

    useEffect(() => {
        console.log(updateItem)
        setCheckActive(updateItem?.isActived);
        setValue("departmentId", updateItem?.departmentId);
        setValue("groupId1", updateItem?.groupId1);
        setValue("groupId2", updateItem?.groupId2);
        setValue("zionId", updateItem?.zionId ? updateItem?.zionId : 21);
        setValue("districtId", updateItem?.districtId);
        setValue("positionId", updateItem?.positionId);
        setValue("wMTeamId", updateItem?.wMTeamId);
        setValue("zionAreaId", updateItem?.zionAreaId);
        setValue("dateOfBirth", updateItem?.dateOfBirth);
        setValue("herdsmanTakeCareId", updateItem?.herdsmanTakeCareId);
        setValue("herdsmanType", updateItem?.herdsmanType ? updateItem?.herdsmanType : 1);
        setHerdsmanType(updateItem?.herdsmanType ? updateItem?.herdsmanType : 1);
    }, [])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }

        const reader = new FileReader();
        if (data.avaImage && data.avaImage.length > 0) //Gán file vào data
            data.avaImage = data.avaImage[0];
        onSubmitAddEdit({ ...data, IsCreateUser: false, id: updateItem?.id, documentUploadId: updateItem?.documentUploadId, fromCustomerId: updateItem?.fromCustomerId, herdsmanType: herdsmanType, zionId:21 });
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Họ và tên đệm <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="firstName"
                        maxLength={100 }
                        defaultValue={updateItem?.firstName}
                        placeholder="Nhập họ và tên đệm"
                        ref={register({ required: true })}
                    />
                    {errors.firstName && errors.firstName.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-4">
                    <label>Tên <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="lastName"
                        maxLength={50}
                        defaultValue={updateItem?.lastName}
                        placeholder="Nhập tên"
                        ref={register({ required: true })}
                    />
                    {errors.lastName && errors.lastName.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-4">
                    <label>Giới tính</label>
                    <select className="form-control" name="gender" ref={register()} defaultValue={updateItem?.gender}>
                        <option value="1">Nam</option>
                        <option value="0">Nữ</option>
                    </select>
                </div>
            </div>
            {/*
                <div className="row">

                    <div className="form-group col-md-4">
                        <label>Email <span className='required'>*</span></label>
                        <input
                            className="form-control"
                            type="text"
                            name="email"
                            defaultValue={updateItem?.email}
                            placeholder="Email"
                            ref={register({ required: true })}
                        />
                        {errors.email && errors.email.type === "required" && (
                            <span className="error">Trường này là bắt buộc</span>
                        )}
                    </div>
                    <div className="form-group col-md-4">
                        <label>Số điện thoại</label>
                        <input
                            className="form-control"
                            type="text"
                            name="phoneNumber"
                            defaultValue={updateItem?.phoneNumber}
                            placeholder="Số điện thoại"
                            ref={register()}
                        />
                    </div>
                    <div className="form-group col-md-4">
                        <label>Quận/ huyện</label>
                        <Select
                            isClearable
                            {...register("districtId")}
                            defaultValue={updateItem?.districtId ? { label: updateItem?.districtName, value: updateItem?.districtId } : null}
                            placeholder="Quận/ huyện"
                            closeMenuOnSelect={true}
                            onChange={(data) => setValue("districtId", data ? data.value : null)}
                            options={district.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                        />
                    </div>
                </div> */
            }
            <div className="row">
                {
                    /*
                    <div className="form-group col-md-4">
                    <label>Địa chỉ</label>
                    <input
                        className="form-control"
                        type="text"
                        name="address"
                        defaultValue={updateItem?.address}
                        placeholder="Địa chỉ"
                        ref={register()}
                    />
                </div>
                    */
                }
                
                <div className="form-group col-md-4">
                    <label>Chức vụ <span className='required'>*</span></label>
                    <Select
                        isClearable
                        {...register("positionId", { required: true })}
                        defaultValue={updateItem?.positionId ? { label: updateItem?.positionName, value: updateItem?.positionId } : null}
                        placeholder="Chức vụ"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("positionId", data ? data.value : null)}
                        options={position.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {errors.positionId && errors.positionId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-4">
                    <label>Địa vực <span className='required'>*</span></label>
                    <DepartmentTreeSelect
                        {...register("departmentId", { required: true })}
                        onChange={(data) => setValue("departmentId", data ? data.value : null)}
                        selected={updateItem?.departmentId ? { label: updateItem?.departmentName, value: updateItem?.departmentId } : null}
                        placeholder="Chọn địa vực"
                        options={department}
                    />
                    {errors.departmentId && errors.departmentId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-4">
                    <label>Khu vực <span className='required'>*</span></label>
                    <Select
                        isClearable
                        {...register("zionAreaId", { required: true })}
                        defaultValue={updateItem?.zionAreaId ? { label: updateItem?.zionAreaName, value: updateItem?.zionAreaId } : null}
                        placeholder="Khu vực"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("zionAreaId", data ? data.value : null)}
                        options={zionArea.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {errors.zionAreaId && errors.zionAreaId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            {/*<div className="row">*/}
                
            {/*    <div className="form-group col-md-8">*/}
            {/*        <label>Mô tả</label>*/}
            {/*        <input*/}
            {/*            className="form-control"*/}
            {/*            type="text"*/}
            {/*            name="description"*/}
            {/*            defaultValue={updateItem?.description}*/}
            {/*            placeholder="Mô tả"*/}
            {/*            ref={register()}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*</div>*/}
           
            <div className="row">
                <div className="form-group col-md-4">
                    <label>Thuộc WM</label>
                    <Select
                        isClearable
                        {...register("wMTeamId")}
                        defaultValue={updateItem?.wmTeamId ? { label: updateItem?.wmTeamName, value: updateItem?.wmTeamId } : null}
                        placeholder="WM"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("wMTeamId", data ? data.value : null)}
                        options={wMTeam.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                </div>
                <div className="form-group col-md-6">
                    <div className="row">
                        <div className="form-group col-md-12">
                            <label>Trạng thái hoạt động</label>
                            <div className="switch-button switch-button-yesno ml-5">
                                <input
                                    type="checkbox"
                                    checked={checkActive}
                                    name="isActived"
                                    id="isActived"
                                    ref={register()}
                                    onChange={() => setCheckActive(prev => !prev)}
                                />
                                <span><label htmlFor="isActived"></label></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}