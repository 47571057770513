const headCellsListDepartment = [
    // {
    //     id: 'Parent.Name',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Ban ngành cấp trên',
    //     className: 'pt-3 pb-3',
    //     style: { minWidth: "150px" }
    // },
    {
        id: 'Name',
        numeric: false,
        disablePadding: false,
        label: 'Tên địa vực',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: true,
        label: 'Mô tả',
        className: 'pt-3 pb-3',
        style: { minWidth: "150px" }
    },
    {
        id: 'memberCount',
        numeric: false,
        disablePadding: true,
        label: 'SL thánh đồ',
        className: 'pt-3 pb-3',
        style: { minWidth: "60px" }
    },
    //{
    //    id: 'talentBaptemAmount',
    //    numeric: false,
    //    disablePadding: true,
    //    label: 'Số trái',
    //    className: 'pt-3 pb-3',
    //    style: { minWidth: "60px" }
    //},
    //{
    //    id: 'currentRank',
    //    numeric: false,
    //    disablePadding: true,
    //    label: 'Cấp/bậc',
    //    className: 'pt-3 pb-3',
    //    style: { minWidth: "50px" }
    //},
    {
        id: 'IsActived',
        numeric: false,
        disablePadding: false,
        label: 'Trạng thái',
        className: 'pt-3 pb-3',
        style: { width: "70px" }
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
        style: { width: "60px" }
    },
];

export default headCellsListDepartment;
export const headCellsListDepartmentRankConfirm = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: false,
        label: 'Tên biệt đội',
        className: 'pt-3 pb-3',
        
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: true,
        label: 'Mô tả',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'talentAmount',
        numeric: false,
        disablePadding: true,
        label: 'Ta-lâng',
        className: 'pt-3 pb-3',
        style: { minWidth: "60px" }
    },
    {
        id: 'talentBaptemAmount',
        numeric: false,
        disablePadding: true,
        label: 'Số trái',
        className: 'pt-3 pb-3',
        style: { minWidth: "60px" }
    },
    {
        id: 'currentRank',
        numeric: false,
        disablePadding: true,
        label: 'Hạng hiện tại',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'rankConfirm',
        numeric: false,
        disablePadding: true,
        label: 'Hạng mới',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'IsActived',
        numeric: false,
        disablePadding: false,
        label: 'Duyệt thăng hạng',
        className: 'pt-3 pb-3',
        style: { width: "80px" }
    }
];