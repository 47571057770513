import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListWMTeam = (pageIndex = 1, pageSize = 10, sortExpression = "", code = "", name = "") => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    // code && params.append("code", code);
    // name && params.append("name", name);
    return service.get(ApiUrl.GetListWMTeam, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailWMTeam = (id) => {
    return service.get(ApiUrl.GetDetailWMTeam.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateWMTeam = (data) => {
    return service.post(ApiUrl.CreateWMTeam, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateWMTeam = (data) => {
    return service.put(ApiUrl.UpdateWMTeam, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteWMTeam = (id) => {
    return service.delete(ApiUrl.DeleteWMTeam.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentWMTeam = (id) => {
    return service.delete(ApiUrl.DeletePermanentWMTeam.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveWMTeam = (id) => {
    return service.put(ApiUrl.ActiveWMTeam.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveWMTeam = (id) => {
    return service.put(ApiUrl.DeActiveWMTeam.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}

export const GetLookupWMTeam = () => {
    return service.get(ApiUrl.GetLookupWMTeam).then((res) => { return res }).catch((err) => { throw err });
}