import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListDepartmentByFestival = (festivalId) => {
    const params = new URLSearchParams();
    params.append("festivalId", festivalId);
    return service.get(ApiUrl.MemberCountGetListDepartmentByFestival, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetListZionAreaByFestival = (festivalId) => {
    const params = new URLSearchParams();
    params.append("festivalId", festivalId);
    return service.get(ApiUrl.MemberCountGetListZionAreaByFestival, params).then((res) => { return res }).catch((err) => { throw err });
}
export const CreateOrUpdateDepartment = (data) => {
    return service.post(ApiUrl.MemberCountCreateOrUpdateDepartment, data).then((res) => { return res }).catch(err => { throw err });
}
export const CreateOrUpdateZionArea = (data) => {
    return service.post(ApiUrl.MemberCountCreateOrUpdateZionArea, data).then((res) => { return res }).catch(err => { throw err });
}