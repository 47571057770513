import React, { useEffect, useState, useRef } from 'react';
import { getUserInfo, ShowXepHang } from '../../utils/configuration';
import ReactPlayer from 'react-player/lazy';
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import MyExaminationWidget from './components/my-examination-widget';
import HerdsmanRankCupWidget from './components/herdsman-rank-cup-widget';
import SwatTrundoHerdsmanRankCupWidget from './swat-trundo/swat-trundo-herdsman-rank-cup-widget';
import SwatTrundoDepartmentRankCupWidget from './swat-trundo/swat-trundo-department-rank-cup-widget';
import HMAcademyDepartmentRankCupWidget from './academies/swat-academy-department-rank-cup-widget';
import SwatTrundoZionAreaRankCupWidget from './swat-trundo/swat-trundo-zion-area-rank-cup-widget';
import SwatTrundoWMTeamRankCupWidget from './swat-trundo/swat-trundo-wmteam-rank-cup-widget';
import HerdsmanNiniveRankCupWidget from './components/herdsman-ninive-rank-cup-widget';
import DepartmentRankCupWidget from './components/department-rank-cup-widget';
import DeliveryRankCupWidget from './components/delivery-rank-cup-widget';
;export default function ExamDashboard() {
    //var userRole: string;
    const userInfo = getUserInfo();
    const [week, setWeek] = useState();
    const dayOfWeek = new Date().getDay();
    const hour = new Date().getHours();
    const limit = 10;
    useEffect(() => {
        //if (!(ShowXepHang ||
        //    (userInfo && userInfo.email === "tranvanhao@gmail.com") ||
        //    (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")))))

            if (!ShowXepHang)
            window.location.replace("/");

        //setTimeout(() => {
        //    const elements = document.getElementsByClassName("highcharts-credits");
        //    const elementsArray = Array.from(elements);

        //    elementsArray.forEach(element => {
        //        element.style.display = "none";
        //    });
        //}, 100);
        GetCurrentWeek();
    }, [])

    const GetCurrentWeek = async () => {
        try {
             const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
            }
        } catch (err) {
            ;
            
        } finally {
            ;
        }
    };

    return (
        <>
            {/* Tạm thời tắt và bật sau*/
                //(userInfo && userInfo.email === "tranvanhao@gmail.com") ||
                //(userInfo && userInfo.userRoles &&
                //    (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN") || (dayOfWeek == 6 && hour >= 6))) ?
                <>
                    <div className="card" style={{ backgroundColor: '#68a1c3' }}>
                        <h5 className="card-header" style={{ backgroundColor: '#68a1c3', color: 'white', fontWeight: 'bold' }}>LH HOAN HỈ CHUYỂN NHÀ</h5>
                        <div className="card-body">
                            <SwatTrundoHerdsmanRankCupWidget
                                limit={limit}
                            />
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <SwatTrundoZionAreaRankCupWidget
                                        limit={limit}
                                    />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <SwatTrundoDepartmentRankCupWidget
                                        limit={limit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="card" style={{ backgroundColor: '#68a1c3' }}>
                                <h5 className="card-header" style={{ backgroundColor: '#68a1c3', color: 'white', fontWeight: 'bold' }}>LH PHÁT BIỂU LỜI</h5>
                                <div className="card-body">
                                    <HMAcademyDepartmentRankCupWidget
                                        limit={10}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <div className="card" style={{ backgroundColor: '#68a1c3' }}>
                                <h5 className="card-header" style={{ backgroundColor: '#68a1c3', color: 'white', fontWeight: 'bold' }}>LH RUNNING 7</h5>
                                <div className="card-body">
                                    {/*<div className="row">*/}
                                    {/*    <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                                    {/*<SwatTrundoWMTeamRankCupWidget*/}
                                    {/*    limit={12}*/}
                                    {/*/>       */}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <SwatTrundoWMTeamRankCupWidget
                                        limit={12}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                    
                </>
                
            }
            
            
            
        </>
    )
}