import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import { headCellsHerdsmanPoint, headCellsHerdsmanPointDaily } from './head-cell-list';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, sendReactGaEvent, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import dayjs from 'dayjs';

import * as reportAcademyAction from "../../redux/store-sarang/report-academy/report-academy-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as zionManagementAction from "../../redux/store-sarang/zion/zion-management.store";
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";

import { optionsCustomerStatus, permission } from '../../common/constant';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import DepartmentHelper from '../list-department/helper/department-helper';
import FormAddEditAcademyPoint from './components/form-add-edit-report-academy';
import { patternNumber } from '../../common/validatePhoneNumber';
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import MobileHeaderSarang from '../../components/mobile-header/mobile-header-sarang';
import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import Divider from '@mui/material/Divider';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function MyReportAcademy() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    const userInfo = getUserInfo();
    // -- Data to map with ids
    const [department, setDepartment] = useState([]);
    const [herdsman, setHerdsman] = useState([]);
    const [optionsHerdsman, setOptionsHerdsman] = useState();
    const [zion, setZion] = useState([]);
    const [sumaryPoint, setSumaryPoint] = useState([]);
    const [dailyPoint, setDailyPoint] = useState([]);
    const [showIndexHerdsman, setShowIndexHerdsman] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(null);
    const [openItem, setOpenItem] = useState(null);
    const [selectedHerdsman, setSelectedHerdsman] = useState(null);
    const [week, setWeek] = useState();

    const [isOpenCompleteDialog, setOpenCompleteDialog] = useState(false);
    const [isOpenCancelDialog, setOpenCancelDialog] = useState(false);

    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(0);
    const [totalItem, setTotalItem] = useState();

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetListNoPermiss(); //.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                //setDepartment(DepartmentHelper.PrepareSortData(res.content.items.filter(x => (x.parentId == null || x.parentId === undefined) && x.id !== 53)));
                setDepartment(res.content.items.filter(x => (x.parentId == null || x.parentId === undefined) && x.id !== 53));
                //setDepartmentHelping(DepartmentHelper.PrepareSortData(res.content.items.filter(x=>x.id!==53)));
            }
        } catch (err) {
            throw err;
        }
    }
    
    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                setHerdsman(res.content);
                setOptionsHerdsman(res.content.map(item => { return { label: item.name, value: item.id } }));
            }

            const res1 = await herdsmanManagementAction.GetLookupHerdsman(false);
            if (res1 && res1.content) {
                setHerdsman(res1.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const getLookupZion = async () => {
        try {
            const res = await zionManagementAction.GetLookupZion();
            if (res && res.content) {
                setZion(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const GetCurrentWeek = async () => {
        try {
            const res = await examinationAction.GetExactlyCurrentWeek();

            if (res && res.content) {
                setWeek(res.content);
            }
        } catch (err) {
            ;

        } finally {
            ;
        }
    };

    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupDepartment(),
                getLookupHerdsman(),
                getLookupZion(),
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }
    
    useEffect(() => {
        GetCurrentWeek();
        fetchData();
    }, []);

    useEffect(() => {
        GetAcademyPoint(week?.week, week?.month, week?.year);
    }, [week]);

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem]);

    const GetAcademyPoint = async (week = undefined, month = undefined, year = undefined, departmentId = undefined, herdsmanId = undefined) => {
        if (!week || !month || !year) return;
        showLoading(true);
        try {
            const res = await reportAcademyAction.GetAcademyPoint(week, month, year, departmentId, herdsmanId);
    
            if (res && res.content && res.content) {
                setSumaryPoint(res.content);
                setTotalItem(res.content.length);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const GetListReportAcademy = async (herdsmanId = undefined, week = undefined, herdsmanPoint=undefined) => {
        showLoading(true);
        try {
            if (herdsmanPoint && herdsmanPoint.dailyPoint && herdsmanPoint.id)
                setDailyPoint(herdsmanPoint.dailyPoint);
            else {
                const res = await reportAcademyAction.GetListReportAcademy(herdsmanId, week);

                if (res && res.content) {
                    setDailyPoint(res.content);
                    if (herdsmanPoint)
                        herdsmanPoint.dailyPoint = res.content;
                }
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const handleSubmitAddEditReport = async (data) => {
        showLoading(true);

        let formData = new FormData();
        try {
            let res = null;

            if (!data.id) {
                res = await reportAcademyAction.CreateReportAcademy({ ...data, isActived:true });
            } else {
                res = await reportAcademyAction.UpdateReportAcademy({ ...data, isActived: true });
            }
            if (res && res.content) {
                let detail = null;
                if (openItem.id)
                    detail = await reportAcademyAction.GetDetailAcademyPoint(openItem.id);
                else
                    detail = await reportAcademyAction.GetDetailAcademyPointByWeek(data?.herdsmanId, data?.week);
                if (detail && detail.content) {
                    openItem.nmQuantity = detail.content.nmQuantity;
                    openItem.omQuantity = detail.content.omQuantity;
                    openItem.totalPoint = detail.content.totalPoint;
                    openItem.basicPoint = detail.content.basicPoint;
                    openItem.id = openItem.id ? openItem.id : detail.content.id;
                    openItem.dailyPoint = undefined;
                }
                ShowNotification(
                    viVN.Success["CreateSuccess"],
                    NotificationMessageType.Success
                );
                GetListReportAcademy(data?.herdsmanId, data?.week, openItem);
            }
            showLoading(false);
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }

    
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenAddSwatRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
    const [isOpenCreateCalendarDialog, setOpenCreateCalendarDialog] = useState(false);
    const [isOpenViewCustomerDialog, setOpenViewCustomerDialog] = useState(false);
    const [isOpenConfirmCreateCalendarDialog, setOpenConfirmCreateCalendarDialog] = useState(false);
    const [isOpenAddSwatDialog, setOpenAddSwatDialog] = useState(false);
    const [swatItem, setSwatItem] = useState(null);

    const openEditDialog = (item) => {
        console.log(item);
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }

    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setOpenCreateCalendarDialog(false);
        setOpenViewCustomerDialog(false);
        setSelectedItem(null);

        setOpenAddSwatDialog(false);
        setSwatItem(null);
    }

    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }

    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenCancelDialog(false);
        setOpenCompleteDialog(false);
        setOpenChangeStatusDialog(false);

        setSelectedItem(null);

        setOpenConfirmCreateCalendarDialog(false);
    }

    const handleConfirm = async () => {
        
        try {
            let res = null;

            if (isOpenCompleteDialog) {
                res = await reportAcademyAction.CompleteBasicPoint({ festivalCOGId: selectedItem?.festivalCOGId, herdsmanId: selectedItem?.herdsmanId });
                if (res.content.status) {
                    selectedItem.completeBasicPoint = true;
                    selectedItem.allowClearBasicPoint = userInfo && userInfo.userRoles && (
                        userInfo.userRoles.includes(permission.Chapsu) ||
                        userInfo.userRoles.includes(permission.Admin) ||
                        userInfo.userRoles.includes(permission.Diavuctruong) ||
                        userInfo.userRoles.includes(permission.Dvt_hn)
                    );
                    ShowNotification(
                        "Cập nhật thành công",
                        NotificationMessageType.Success
                    );
                }
            }
            else if (isOpenCancelDialog) {
                res = await reportAcademyAction.ClearBasicPoint({ festivalCOGId: selectedItem?.festivalCOGId, herdsmanId: selectedItem?.herdsmanId });
                if (res.content.status) {
                    selectedItem.completeBasicPoint = false;
                    ShowNotification(
                        "Hủy thành công",
                        NotificationMessageType.Success
                    );
                }
            }
            
            if (res.content.status) {
                //GetAcademyPoint(week?.week, week?.month, week?.year);
                let detail = await reportAcademyAction.GetDetailAcademyPoint(selectedItem.id);
                if (detail && detail.content) {
                    selectedItem.nmQuantity = detail.content.nmQuantity;
                    selectedItem.omQuantity = detail.content.omQuantity;
                    selectedItem.totalPoint = detail.content.totalPoint;
                    selectedItem.basicPoint = detail.content.basicPoint;
                }
                GetListReportAcademy(selectedItem?.herdsmanId, selectedItem?.week);
            }

            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            console.log(err);
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    const handleCheck = (item, e) => {
        if (e.target.checked) {
            setOpenCompleteDialog(true);
            openConfirmDialog(item);
        }
        else {
            setOpenCancelDialog(true);
            openConfirmDialog(item);
        }
    }

    const renderRow = (item, index, showIndex) => {
        return <>
            <tr style={{ cursor: "pointer" }} key={index+showIndex} onClick={() => {
                setDailyPoint([]);
                if (showIndex == showIndexHerdsman)
                {
                    setShowIndexHerdsman(-1);
                    setOpenItem(null);
                }
                else {
                    setShowIndexHerdsman(showIndex);
                    setOpenItem(item);
                    GetListReportAcademy(item.herdsmanId, item.week, item);
                    setSelectedHerdsman(item.herdsmanName);
                }

            }}>
                {
                    headCellsHerdsmanPoint.map((itemPoint, indexPoint) => <>
                        {
                            itemPoint.id === 'action' ? <td key={indexPoint}></td> :
                                itemPoint.id === 'basicPoint' ? <td key={indexPoint}>
                                    <label className="custom-control custom-checkbox custom-control-inline">
                                        <input
                                            type="checkbox"
                                            checked={item.completeBasicPoint}
                                            name={`chk${item.herdsmanId}`}
                                            id={`chk${item.herdsmanId}`}
                                            ref={register()}
                                            disabled
                                            className="custom-control-input"
                                            onChange={(e) => handleCheck(item, e)}
                                        /><span className="custom-control-label">{item.basicPoint ? item.basicPoint : ""}&nbsp;
                                            {
                                                userInfo && userInfo.userRoles && (
                                                    userInfo.userRoles.includes(permission.Chapsu) ||
                                                    userInfo.userRoles.includes(permission.Admin) ||
                                                    userInfo.userRoles.includes(permission.Diavuctruong) ||
                                                    userInfo.userRoles.includes(permission.Dvt_hn)
                                                ) &&
                                                    !item.completeBasicPoint ? <img title="Bấm hoàn thành mục tiêu" style={{ cursor: 'pointer' }}
                                                        src={require("../../assets/icon/tick.png")}
                                                        alt="Tick" onClick={() => {
                                                            setOpenCompleteDialog(true);
                                                            openConfirmDialog(item);
                                                        }}
                                                /> : <>
                                                        {
                                                            userInfo && userInfo.userRoles && (
                                                                userInfo.userRoles.includes(permission.Chapsu) ||
                                                                userInfo.userRoles.includes(permission.Admin) ||
                                                                userInfo.userRoles.includes(permission.Diavuctruong) ||
                                                                userInfo.userRoles.includes(permission.Dvt_hn)
                                                            ) &&
                                                            item.allowClearBasicPoint && <img title="Hủy hoàn thành mục tiêu" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Tick" onClick={() => {
                                                                    setOpenCancelDialog(true);
                                                                    openConfirmDialog(item);
                                                                }}
                                                            />
                                                        }
                                                </>
                                            }
                                        </span>
                                    </label>
                                    
                                </td> :
                                    <td key={indexPoint}>{item[itemPoint.id]}</td>
                        }
                    </>
                    )
                }
            </tr>
            {
                dailyPoint && dailyPoint.length > 0 &&
                <tr key={ index*100} style={{ display: showIndexHerdsman == showIndex ? "contents" : "none" }}>
                    <td colSpan={7}>
                        <table className="table dataTable table-hover " id="datatable1">
                            <thead>
                                <tr>
                                    {
                                        headCellsHerdsmanPointDaily.map((item) =>
                                            <td key={item.id}
                                                style={item.style ? { ...item.style, fontWeight: 'bold', textAlign: 'center' } : { fontWeight: 'bold', textAlign: 'center' }}
                                            ><b>{item.label}</b></td>)
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dailyPoint.map((itemDailyPoint, index) =>  //filter(x => x.basicPoint === null || x.basicPoint === undefined || x.basicPoint === 0)
                                        <tr>
                                            {
                                                headCellsHerdsmanPointDaily.map((itemPoint, indexPoint) =>
                                                    <>
                                                        {
                                                            itemPoint.id === 'action' ? <td>
                                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                                    title="Sửa"
                                                                    onClick={() => { openEditDialog(itemDailyPoint) }}
                                                                >
                                                                    <i className="far fa-edit"></i>
                                                                </button>
                                                            </td> :
                                                                itemPoint.id === 'totalPoint' ? <td>
                                                                    {itemDailyPoint["nmSumQuantity"] * 5 + itemDailyPoint["omSumQuantity"]}
                                                                </td> :
                                                                    itemPoint.id === 'reportDate' ? <td key={indexPoint}>{dayjs(itemDailyPoint.reportDate).format("DD/MM/YYYY")}</td> :
                                                                        <td key={indexPoint}>{itemDailyPoint[itemPoint.id]}</td>
                                                        }
                                                    </>
                                                )
                                            }
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    </td>
                </tr>
            }
        </>
    }

    const renderRowMobile = (item, index) => {
        return <div className="card" key={index}>
            <div className="d-flex align-items-center" id={`heading-${item.id}-${index}`}>
                <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                    data-toggle="collapse" data-target={`#collapse-${item.id}-${index}`} aria-expanded="false" aria-controls={`collapse-${item.id}-${index}`}
                    onClick={() => {
                        setDailyPoint([]);
                        setOpenItem(item);
                        GetListReportAcademy(item.herdsmanId, item.week, item);
                        setSelectedHerdsman(item.herdsmanName);
                    }}
                >
                    <div className='row'>
                        <div className="col-6">
                            <div className='mt-0'>
                                <b>#{item.rank} </b>&nbsp;
                                <span>
                                    <b>{item.herdsmanName}</b>
                                </span>
                            </div>
                            <div className='mt-0'><b>Địa vực: </b>
                                <span>{item.zionName}</span>
                            </div>
                            <div className='mt-0'><b>Tổng điểm: </b>
                                <span>{item.totalPoint}</span>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className='mt-0'>
                                <b>Số lần giáo dục: </b>&nbsp;
                                <span>
                                    {item.nmQuantity}
                                </span>
                            </div>
                            <div className='mt-0'><b>Số lần phát biểu: </b>
                                <span>{item.omQuantity}</span>
                            </div>
                            <div className='mt-0'><b>Mục tiêu cơ bản: </b>
                                <label className="custom-control custom-checkbox custom-control-inline">
                                    <input
                                        type="checkbox"
                                        checked={item.completeBasicPoint}
                                        name={`chk${item.herdsmanId}`}
                                        id={`chk${item.herdsmanId}`}
                                        ref={register()}
                                        disabled
                                        className="custom-control-input"
                                        onChange={(e) => handleCheck(item, e)}
                                    /><span className="custom-control-label">{item.basicPoint ? item.basicPoint : ""}&nbsp;
                                        {
                                            userInfo && userInfo.userRoles && (
                                                userInfo.userRoles.includes(permission.Chapsu) ||
                                                userInfo.userRoles.includes(permission.Admin) ||
                                                userInfo.userRoles.includes(permission.Diavuctruong) ||
                                                userInfo.userRoles.includes(permission.Dvt_hn)
                                            ) &&
                                                !item.completeBasicPoint ? <img title="Bấm hoàn thành mục tiêu" style={{ cursor: 'pointer' }}
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick" onClick={() => {
                                                        setOpenCompleteDialog(true);
                                                        openConfirmDialog(item);
                                                    }}
                                            /> : <>
                                                {
                                                    userInfo && userInfo.userRoles && (
                                                            userInfo.userRoles.includes(permission.Chapsu) ||
                                                            userInfo.userRoles.includes(permission.Admin) ||
                                                            userInfo.userRoles.includes(permission.Diavuctruong) ||
                                                            userInfo.userRoles.includes(permission.Dvt_hn)
                                                        ) &&
                                                    item.allowClearBasicPoint && <img title="Hủy hoàn thành mục tiêu" style={{ cursor: 'pointer' }}
                                                        src={require("../../assets/icon/cancel.png")}
                                                        alt="Tick" onClick={() => {
                                                            setOpenCancelDialog(true);
                                                            openConfirmDialog(item);
                                                        }}
                                                    />
                                                }
                                            </>
                                        }
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id={`collapse-${item.id}-${index}`} className="collapse" aria-labelledby={`heading-${item.id}-${index}`} data-parent="#accordion">
                <div className="card-body">
                    <table className="table dataTable table-hover">
                        <tbody>
                            {
                                dailyPoint && dailyPoint.length > 0 && dailyPoint.map((itemDailyPoint, indexDaylyPoint) => <tr key={indexDaylyPoint}>
                                    <td>
                                        <div className='mt-0'>
                                            <b>Ngày: </b>
                                            <span>
                                                <b>{dayjs(itemDailyPoint.reportDate).format("DD/MM/YYYY")}</b>
                                            </span>
                                        </div>
                                        <div className='mt-3'><b>Số lần giáo dục: </b>
                                            <span>{itemDailyPoint.nmSumQuantity}</span>
                                        </div>

                                    </td>
                                    <td>
                                        <div className='mt-0'><b>Số lần phát biểu: </b>
                                            <span>{itemDailyPoint.omSumQuantity}</span>
                                        </div>
                                        <div className='mt-3'><b>Tổng điểm: </b>
                                            <span>{itemDailyPoint["nmSumQuantity"] * 5 + itemDailyPoint["omSumQuantity"]}</span>&nbsp;
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Sửa"
                                                onClick={() => { openEditDialog(itemDailyPoint) }}
                                            >
                                                <i className="far fa-edit"></i>
                                            </button>
                                        </div>

                                    </td>

                                </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <div className="card">
                    <h5 className="card-header">Nhập báo cáo phát biểu tuần thứ <b>{week?.week}</b>, ngày <b>{dayjs(week?.fromDate).format("DD/MM/YYYY HH:mm:ss")}</b>-<b>{dayjs(week?.toDate).format("DD/MM/YYYY HH:mm:ss")}</b></h5>
                </div>
                <div id="accordion">
                    {
                        sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.filter(x => x.herdsmanId == userInfo.herdsmanId).map((item, index) => <>
                            {
                                renderRowMobile(item, 2000+index)
                            }
                        </>
                        )
                    }
                    {
                        sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.filter(x => x.herdsmanId != userInfo.herdsmanId).map((item, index) => <>
                            {
                                renderRowMobile(item, index)
                            }
                        </>
                        )
                    }
                </div>

            </div>
        </>
    }

    const renderTableForDesktop = () => {
        return <>
            <div className="card">
                <h5 className="card-header">Nhập báo cáo phát biểu tuần thứ <b>{week?.week}</b>, ngày <b>{dayjs(week?.fromDate).format("DD/MM/YYYY HH:mm:ss")}</b>-<b>{dayjs(week?.toDate).format("DD/MM/YYYY HH:mm:ss")}</b></h5>
                <div className="card-body">
                    <div className="row text-center">
                        <div className="col-12 mb-2 dataTables" ref={scrollRoot}>
                            <table className="table dataTable table-hover" id="datatable" ref={scrollTable} >
                                <thead>
                                    <tr>
                                        {
                                            headCellsHerdsmanPoint.map((item) =>
                                                <td key={item.id}
                                                    style={item.style ? { ...item.style, fontWeight: 'bold', textAlign: 'center' } : { fontWeight: 'bold', textAlign: 'center' }}
                                                ><b>{item.label}</b></td>)
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.filter(x=>x.herdsmanId==userInfo.herdsmanId).map((item, index) => <>
                                            {
                                                renderRow(item, index, 2000)
                                            }
                                        </>
                                        )
                                    }
                                    {
                                        sumaryPoint && sumaryPoint.length > 0 && sumaryPoint.filter(x => x.herdsmanId != userInfo.herdsmanId).map((item, index) => <>
                                            {
                                                renderRow(item, index, index)
                                            }
                                        </>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                    {
                        scrollTableWidth ?
                            <div className='scrollRoot'
                                onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                            >
                                <div style={{ width: `${scrollTableWidth}px` }}>
                                    &nbsp;
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    //renderTableForDesktop()
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenAddSwatRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>

            <ModalSubmitForm
                title="Nhập số lần phát biểu (lễ hội lời)"
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                {
                    <FormAddEditAcademyPoint
                        //department={DepartmentHelper.RenderOptions(department)}
                        // ===
                        updateItem={selectedItem}
                        onSubmitAddEdit={handleSubmitAddEditReport}
                        herdsmanName={selectedHerdsman }
                    />
                }
                
            </ModalSubmitForm>

            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa bản ghi này không?":
                        isOpenCancelDialog ? `AC có chắc chắn muốn HỦY hoàn thành mục tiêu cơ bản cho AE/CE ${selectedItem?.herdsmanName}?` : 
                            isOpenCompleteDialog ? `AC có chắc chắn muốn xác nhận hoàn thành mục tiêu cơ bản cho AE/CE ${selectedItem?.herdsmanName}?`: 
                    isOpenChangeStatusDialog ? `Bạn có chắc chắn muốn thay đổi trạng thái cho chiên <b>"${selectedItem?.fullName}"</b> thành <b> Bước "${1}"</b>?` :
                    isOpenConfirmCreateCalendarDialog ? `Bạn có chắc chắn muốn tạo lịch cho chiên <b>"${selectedItem?.fullName}"</b>?` : ""
                }
                open={isOpenDeleteDialog || isOpenCancelDialog || isOpenCompleteDialog  || isOpenChangeStatusDialog || isOpenCreateCalendarDialog}
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}