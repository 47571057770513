import axios from "axios";
import * as apiConfig from "./api-config";
import * as configuration from "../utils/configuration";
import * as Page500ErrorTypes from "../common/error-types-500";
import { GenerateDeviceId } from "../common/tools";
import { useLaunchParams, isTMA, retrieveLaunchParams, mockTelegramEnv, parseInitData } from '@telegram-apps/sdk-react';
//const initDataRaw = "user=%7B%22id%22%3A279058397%2C%22first_name%22%3A%22Vladislav%22%2C%22last_name%22%3A%22Kibenko%22%2C%22username%22%3A%22vdkfrost%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-3788475317572404878&chat_type=private&auth_date=1709144340&hash=371697738012ebd26a111ace4aff23ee265596cd64026c8c3677956a85ca1827"
const {
  TokenKey,
  TokenPrefix,
  getCookies,
  removeCookies,
  DomainAdminSide,
  setCookiesUser,
} = configuration;

configuration.setConfiguration(
  configuration.ApiServerKey.APP_API_ROOT,
  apiConfig.api
);
const apiRoot = configuration.getConfiguration(
  configuration.ApiServerKey.APP_API_ROOT
);

const isHandlerEnabled = true;

const requestHandler = (request) => {
  if (isHandlerEnabled) {
    // request.headers.common["Accept"] = "application/json";
    request.headers.common["Content-Type"] = "application/json; charset=utf-8";
    request.headers.common["Accept"] =
      "application/json, text/javascript, */*; q=0.01";
    request.headers.common["Access-Control-Allow-Origin"] = "*";
  }

  let userInfo = getCookies(TokenKey.token);
  if (userInfo) {
    request.headers.common["Authorization"] = `${TokenPrefix} ${userInfo}`;
  }

  return request;
};

const successHandler = (response, isHandlerEnabled) => {
  if (isHandlerEnabled) {
    //TODO: Do Success Handler
  }

  return response;
};

const errorHandler = (error, isHandlerEnabled) => {
  if (isHandlerEnabled) {
    //TODO: Do Error Handler
  }
  if (error.response.status === 401) {
    return Promise.reject(error);
  }

  return Promise.reject({
    ...(error.response
      ? error.response.data
      : {
          errorType: Page500ErrorTypes.UnhandleException,
          errorMessage: "UnhandleException",
        }),
  });
};

const axios_instance = axios.create({
  baseURL: apiRoot,
  responseType: "json",
});

axios_instance.interceptors.request.use(
  (request) => requestHandler(request, isHandlerEnabled),
  (error) => errorHandler(error, isHandlerEnabled)
);

export async function refreshToken() {
  try {
    let DeviceId = GenerateDeviceId();
    const response = await axios
      .create({
        baseURL: apiRoot,
      })
      .post("/api/Account/RefreshToken", {
        refreshToken: getCookies(TokenKey.refreshToken),
        accessToken: getCookies(TokenKey.token),
        returnUrl: DomainAdminSide,
        DeviceId: DeviceId
      });
    removeCookies("isShowDialog");
    setCookiesUser(response);
  } catch (error) {
    // console.log("!!!!", error);
    //removeCookies("isShowDialog");
    //removeCookies("isLockScreen");
    //removeCookies(TokenKey.token);
    //removeCookies(TokenKey.refreshToken);
    //removeCookies(TokenKey.returnUrl);
    //  window.location.replace(DomainAdminSide + "/dang-nhap");
      login();
  }
}

const login = async () => {
    removeCookies("isShowDialog");
    removeCookies("isLockScreen");
    removeCookies(TokenKey.token);
    removeCookies(TokenKey.refreshToken);
    removeCookies(TokenKey.returnUrl);
    if (isTMA('simple')) {
        const { initDataRaw } = retrieveLaunchParams();
        console.log('telegram login refreshtoken')
        console.log(initDataRaw);
        try {
            let DeviceId = GenerateDeviceId();
            const response = await axios
                .create({
                    baseURL: apiRoot,
                })
                .post("/api/admin/Account/TelegramLogin", {
                    EncodedUrl: initDataRaw,
                    DeviceId: DeviceId
                });
            setCookiesUser(response);
        }
        catch (error) {
            window.location.replace(DomainAdminSide + "/dang-nhap");
        }
    }
    else {
        //try {
        //    console.log('normal login')
        //    let DeviceId = GenerateDeviceId();
        //    const response = await axios
        //        .create({
        //            baseURL: apiRoot,
        //        })
        //        .post("/api/admin/Account/TelegramLogin", {
        //            EncodedUrl: initDataRaw,
        //            DeviceId: DeviceId
        //        });
        //    setCookiesUser(response);
        //}
        //catch (error) {
        //    window.location.replace(DomainAdminSide + "/dang-nhap");
        //}
        window.location.replace(DomainAdminSide + "/dang-nhap");        
    }
}

let refreshing_token = null;

axios_instance.interceptors.response.use(
  (response) => successHandler(response, isHandlerEnabled),
  async (error) => {
    const config = error.config;
    if (error.response && error.response.status === 401 && !config._retry) {
      config._retry = true;
      try {
        refreshing_token = refreshing_token ? refreshing_token : refreshToken();
        await refreshing_token;
        refreshing_token = null;
        config.headers["Authorization"] =
          "Bearer " + getCookies(TokenKey.token);
        return axios_instance(config);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject({
      ...(error.response
        ? error.response.data
        : {
            errorType: Page500ErrorTypes.UnhandleException,
            errorMessage: "UnhandleException",
          }),
    });
  }
);

export default axios_instance;
