import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { formatNumber} from "../../../utils/configuration";


export default function BodyReport(props) {
    const { scrollRoot, scrollTable, data, columnName, nameString } = props;

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

    const renderTableForDesktop = () => {
        return <div className="dataTables freeze-column" ref={scrollRoot}>
            {data && data.length > 0 &&
                <table className="table table-bordered table-hover dataTable" ref={scrollTable} id="datatable">
                    <thead>
                        <tr>
                            <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '100px' }} rowSpan={2}>{nameString}</th>
                            {
                                data && data.length > 0 && data[0].statusQuantities && data[0].statusQuantities.map((item, index) => (
                                    <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }} colSpan={2}>{item.statusName}</th>
                                ))
                            }
                            <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }} rowSpan={2}>Tổng</th>
                        </tr>
                        <tr>
                            {
                                data && data.length > 0 && data[0].statusQuantities && data[0].statusQuantities.map((item, index) => (<>
                                    <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }}>SL</th>
                                    <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle', minWidth: '50px' }}>Điểm</th>
                                </>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((item, index) => (
                                index < data.length - 1 && <tr key={index}>
                                    <td style={{ verticalAlign: 'middle', fontWeight: index == data.length - 1 ? 'bold' : '', textAlign: index === data.length - 1 ? 'center' : '' }}>{item[columnName]}</td>
                                    {
                                        item.statusQuantities && item.statusQuantities.map((itemStatus, itemIndex) => (<>
                                            <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: index === data.length - 1 ? 'bold' : '' }}>{formatNumber(itemStatus.quantity)}</td>
                                            <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: index === data.length - 1 ? 'bold' : '' }}>{formatNumber(itemStatus.point)}</td>
                                        </>
                                        ))
                                    }
                                    <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: index === data.length - 1 ? 'bold' : '' }}>{formatNumber(item.sumaryPoint)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                    <tfoot>
                        {
                            data && data.length > 0 &&
                            <tr>
                                <td style={{ verticalAlign: 'middle', fontWeight: 'bold', textAlign: 'center' }}>{data[data.length - 1][columnName]}</td>
                                {
                                    data[data.length - 1].statusQuantities && data[data.length - 1].statusQuantities.map((itemStatus, itemIndex) => (<>
                                        <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold' }}>{formatNumber(itemStatus.quantity)}</td>
                                        <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold' }}>{formatNumber(itemStatus.point)}</td>
                                    </>
                                    ))
                                }
                                <td style={{ textAlign: 'right', verticalAlign: 'middle', fontWeight: 'bold' }}>{formatNumber(data[data.length - 1].sumaryPoint)}</td>
                            </tr>

                        }
                    </tfoot>
                </table>
            }
        </div>
    }
    
    const renderItemForMobile = () => {
        return <>
            {
                data && data.length > 0 && data.map((item, index) => (
                    <div className="card" key="index" style={{ width: '100%' }}>
                        <h5 className="card-header" style={{ fontWeight: 700, color: '#68a1c3' }}>{item[columnName]}: {formatNumber(item.sumaryPoint)}</h5>
                        <div className="card-body row">
                            {
                                item.statusQuantities && item.statusQuantities.map((itemStatus, itemIndex) => (
                                    <div className="col-6" key={itemIndex}>
                                        <span style={{ fontWeight: 700}}>{itemStatus.statusName}</span>: {formatNumber(itemStatus.quantity)} - {formatNumber(itemStatus.point)}
                                    </div>
                                ))

                            }
                        </div>
                    </div>
                ))
            }
        </>
    }

    return (
        <>
            {
                isDesktopOrLaptop ? renderTableForDesktop() : <></>
            }

            {
                isTabletOrMobile ? renderItemForMobile() : <></>
            }
        </>
    )
}