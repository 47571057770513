import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as memberCountManagementAction from "../../redux/store-sarang/academy-practice/member-count-management.store";
import * as festivalManagementAction from "../../redux/store-sarang/festival-cog/festival-cog-management.store";

import dayjs from 'dayjs';
import Select from "react-select";
import { useMediaQuery } from 'react-responsive';
import { NumericFormat } from 'react-number-format';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "reportDate desc",
    orderBy: "reportDate",
    order: "desc",
}

export default function MemberCountInput() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    const [openSearch, setOpenSearch] = useState(false);

    // -- End Data to map with ids

    const [data, setData] = useState([]);
    const [festivalCog, setFestivalCog] = useState([]);
    const [festivalId, setFestivalId] = useState();


    useEffect(() => {
        //getListByDate(dayjs(new Date()));
        getLookupFestival();
    }, []);

    const getLookupFestival = async () => {
        try {
            const res = await festivalManagementAction.GetLookupFestivalCog();
            if (res && res.content) {
                setFestivalCog(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    };

    const getListByFestival = async (festivalId) => {
        showLoading(true);
        try {
            const res = await memberCountManagementAction.GetListZionAreaByFestival(festivalId);
    
            if (res && res.content) {
                setData(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const onSubmit =async () => {
        showLoading(true);

        try {
            let res = null;
            if (data && data.quantities.length > 0) {
                res = await memberCountManagementAction.CreateOrUpdateZionArea(data);
                if (res && res.content.status) {
                    ShowNotification(
                        viVN.Success["UpdateSuccess"],
                        NotificationMessageType.Success
                    );
                }
            }
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }

    }

    const onSubmitOneRow = async (data1) => {
        //setSearchData(data);
        //let sortExpression = orderBy + ' ' + order;
        //setPage(0);
        //getListSwatActivityReportManagement(1, rowsPerPage, sortExpression, data);
        //if (isTabletOrMobile)
        //    setOpenSearch(false);

        showLoading(true);

        try {
            let res = null;
            let saveData = new Array();
            saveData.push(data1);
            res = await memberCountManagementAction.CreateOrUpdateZionArea({
                festivalCogId: festivalId,
                quantities: saveData
            });

            if (res && res.content.status) {
                ShowNotification(
                    viVN.Success["UpdateSuccess"],
                    NotificationMessageType.Success
                );
            }
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }

    }

    const renderTableForDesktop = () => {
        return data && data.quantities && data.quantities.length > 0 && <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
                <div className="card-body">
                    <table className="table dataTable table-hover " id={`datatableDetail`}>
                        <thead>
                            <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle',  minWidth: '100px'}}>Khu vực</th>
                            <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>SL Đ/ký phát biểu</th>
                            <th></th>
                        </thead>
                        <tbody>
                            {
                                data && data.quantities && data.quantities.map((item, index) => (
                                    <tr key={index }>
                                        <td>{item.zionAreaName}</td>
                                        <td>
                                            <CreateInput item={item} index={index} />
                                        </td>
                                        <td>
                                            <button className="d-inline-block btn btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Lưu"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onSubmitOneRow(item);
                                                }}
                                            >
                                                <i className="fas fa-save"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    }
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                    <div className="card-body p-2 p-md-4">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className="row">
                                <div className="form-group col-12 col-md-6">
                                    {festivalCog ? <>
                                        
                                        <Select
                                            isClearable
                                            placeholder="Chọn lễ hội"
                                            closeMenuOnSelect={true}
                                            onChange={(data) => {
                                                setFestivalId(data ? data.value : null);
                                                getListByFestival(data ? data.value : null);
                                            }}
                                            options={festivalCog}
                                            noOptionsMessage={() => "Không tồn tại"}
                                        />
                                    </>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="col-12 col-md-6 pl-0">
                                    <p className="text-center">
                                        <button type="submit" disabled={!festivalId} className="btn btn-space btn-primary">Lưu tất cả</button>
                                    </p>
                                </div>

                            </div>
                            {
                                renderTableForDesktop()
                            }
                        </form>
                         
                    </div>
                </div>
            </div>
        </div>
    )
}

const CreateInput = (props) => {
    const { item, index } = props;
    const { setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    useEffect(() => {
        setStatusQtt(item.memberQuantity);

    }, [item.memberQuantity]);
    const [statusQtt, setStatusQtt] = useState(null);
    return <>
        <NumericFormat
            className="form-control"
            name={`quantity[${index}][q${item.zionAreaId}]`}
            onValueChange={(values, sourceInfo) => {
                item.memberQuantity = values.value == "" ? 0 : values.value;
                setStatusQtt(item.memberQuantity);
            }}
            value={statusQtt}
            />
    </>
}