import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListByFestival = (festivalId) => {
    const params = new URLSearchParams();
    params.append("festivalId", festivalId);
    return service.get(ApiUrl.HerdsmanAcademyGetListByFestival, params).then((res) => { return res }).catch((err) => { throw err });
}

export const Create = (data) => {
    return service.post(ApiUrl.HerdsmanAcademyCreate, data).then((res) => { return res }).catch(err => { throw err });
}
export const Update = (data) => {
    return service.put(ApiUrl.HerdsmanAcademyUpdate, data).then((res) => { return res }).catch(err => { throw err });
}
export const GetListDepartmentRank = (festivalCogId, limit = undefined, week = undefined, needPermission = false) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    limit && params.append("limit", limit);
    week && params.append("week", week);
    params.append("needPermission", needPermission);
    return service.get(ApiUrl.HerdsmanAcademyGetListDepartmentRank, params).then((res) => { return res }).catch((err) => { throw err });
}