import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListByDate = (date) => {
    const params = new URLSearchParams();
    date && params.append("date", date);
    return service.get(ApiUrl.SwatTrundoGetListByDate, params).then((res) => { return res }).catch((err) => { throw err });
}


export const CreateOrUpdate = (data) => {
    return service.post(ApiUrl.SwatTrundoCreateOrUpdate, data).then((res) => { return res }).catch(err => { throw err });
}

export const GetSumaryHerdsman = (festivalCogId = undefined, departmentId = undefined, zionId=undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    departmentId && params.append("departmentId", departmentId);
    zionId && params.append("zionId", zionId);
    return service.get(ApiUrl.SwatTrundoGetSumaryHerdsman, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetSumaryHerdsmanWM = (festivalCogId = undefined, departmentId = undefined, wmteamId = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    departmentId && params.append("departmentId", departmentId);
    wmteamId && params.append("wmteamId", wmteamId);
    return service.get(ApiUrl.SwatTrundoGetSumaryHerdsmanWM, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetSumaryDepartment = (festivalCogId = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    return service.get(ApiUrl.SwatTrundoGetSumaryDepartment, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetSumaryZion = (festivalCogId = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    return service.get(ApiUrl.SwatTrundoGetSumaryZion, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetSumaryWMTeam = (festivalCogId = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    return service.get(ApiUrl.SwatTrundoGetSumaryWMTeam, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetSumaryHerdsmanByDate = (festivalCogId = undefined, departmentId = undefined, zionId = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    departmentId && params.append("departmentId", departmentId);
    zionId && params.append("zionId", zionId);
    return service.get(ApiUrl.SwatTrundoGetSumaryHerdsmanByDate, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetSumaryHerdsmanByDateWM = (festivalCogId = undefined, departmentId = undefined, wmteamId = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    departmentId && params.append("departmentId", departmentId);
    wmteamId && params.append("zionId", wmteamId);
    return service.get(ApiUrl.SwatTrundoGetSumaryHerdsmanByDateWM, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetSumaryDashboard = (festivalCogId = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    return service.get(ApiUrl.SwatTrundoSumaryDashboard, params).then((res) => { return res }).catch((err) => { throw err });
}

export const GetListHerdsmanRank = (festivalCogId, limit = undefined, week = undefined, needPermission=false) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    limit && params.append("limit", limit);
    week && params.append("week", week);
    params.append("needPermission", needPermission);
    return service.get(ApiUrl.SwatTrundoGetListHerdsmanRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetListDepartmentRank = (festivalCogId, limit = undefined, week = undefined, needPermission = false) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    limit && params.append("limit", limit);
    week && params.append("week", week);
    params.append("needPermission", needPermission);
    return service.get(ApiUrl.SwatTrundoGetListDepartmentRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetListZionAreaRank = (festivalCogId, limit = undefined, week = undefined, needPermission = false) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    limit && params.append("limit", limit);
    week && params.append("week", week);
    params.append("needPermission", needPermission);
    return service.get(ApiUrl.SwatTrundoGetListZionAreaRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetListWMTeamRank = (festivalCogId, limit = undefined, week = undefined, needPermission = false) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    limit && params.append("limit", limit);
    week && params.append("week", week);
    params.append("needPermission", needPermission);
    return service.get(ApiUrl.SwatTrundoGetListWMTeamRank, params).then((res) => { return res }).catch((err) => { throw err });
}