import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as herdsmanAcademyManagementAction from "../../redux/store-sarang/academy-practice/herdsman-academy-management.store";
import * as festivalManagementAction from "../../redux/store-sarang/festival-cog/festival-cog-management.store";

import dayjs from 'dayjs';
import Select from "react-select";
import { useMediaQuery } from 'react-responsive';
import { NumericFormat } from 'react-number-format';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "reportDate desc",
    orderBy: "reportDate",
    order: "desc",
}

export default function HerdsmanAcademyConfirm() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    const [openSearch, setOpenSearch] = useState(false);

    // -- End Data to map with ids

    const [data, setData] = useState([]);
    const [festivalCog, setFestivalCog] = useState([]);
    const [festivalId, setFestivalId] = useState();
    const [chkConfirm, setChkConfirm] = useState([]);
    const [checkActive, setCheckActive] = useState(false);

    useEffect(() => {
        //getListByDate(dayjs(new Date()));
        getLookupFestival();
    }, []);

    const getLookupFestival = async () => {
        try {
            const res = await festivalManagementAction.GetLookupFestivalCog();
            if (res && res.content) {
                setFestivalCog(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    };

    const getListByFestival = async (festivalId) => {
        showLoading(true);
        try {
            const res = await herdsmanAcademyManagementAction.GetListByFestival(festivalId);
    
            if (res && res.content) {
                setData(res.content);
                if (res && res.content && res.content.herdsmanAcademyCompetes)
                    setChkConfirm(res.content.herdsmanAcademyCompetes.map((item) => { return item.isComplete }));
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const onSubmitOneRow = async (data1) => {
        
        showLoading(true);

        try {
            let res = null;
            if (!data1.id)
                res = await herdsmanAcademyManagementAction.Create(data1 );
            else
                res = await herdsmanAcademyManagementAction.Update(data1);
            /*console.log(data1);*/
            if (res && res.content.status) {
                ShowNotification(
                    viVN.Success["UpdateSuccess"],
                    NotificationMessageType.Success
                );
            }
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }

    }

    const renderTableForDesktop = () => {
        return data && data.herdsmanAcademyCompetes && data.herdsmanAcademyCompetes.length > 0 && <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
                <div className="card-body">
                    <table className="table dataTable table-hover " id={`datatableDetail`}>
                        
                        <tbody>
                            {
                                    data && data.herdsmanAcademyCompetes && data.herdsmanAcademyCompetes.map((item, index) => (
                                    <tr key={index }>
                                        <td>{item.herdsmanName}</td>
                                        <td>
                                                <div className="form-group col-md-12">
                                                    <label>Xác nhận hoàn thành</label>
                                                    <CreateCheckBox item={item} index={index} onChange={ onSubmitOneRow} />
                                                </div>
                                        </td>
                                        
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </div>
    }
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                    <div className="card-body p-2 p-md-4">
                        {/*<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">*/}
                            <div className="row">
                                <div className="form-group col-12 col-md-6">
                                    {festivalCog ? <>
                                        
                                        <Select
                                            isClearable
                                            placeholder="Chọn lễ hội"
                                            closeMenuOnSelect={true}
                                            onChange={(data) => {
                                                setFestivalId(data ? data.value : null);
                                                getListByFestival(data ? data.value : null);
                                            }}
                                            options={festivalCog}
                                            noOptionsMessage={() => "Không tồn tại"}
                                        />
                                    </>
                                        :
                                        <></>
                                    }
                                </div>
                                

                            </div>
                            {
                                renderTableForDesktop()
                            }
                        {/*</form>*/}
                         
                    </div>
                </div>
            </div>
        </div>
    )
}

const CreateCheckBox = (props) => {
    const { register} = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const { item, index, onChange } = props;
    const [chkActive, setChkActive] = useState(null);
    useEffect(() => {
        setChkActive(item.isComplete);

    }, [item.isComplete]);
    return <>
        

        <div className="switch-button switch-button-yesno ml-5">
            <input
                type="checkbox"
                checked={chkActive}
                name={`isActived${index}`}
                id={`isActived${index}`}
                ref={register()}
                onChange={(e) => {
                    item.isComplete = e.target.checked;
                    setChkActive(item.isComplete);
                    onChange(item);
                }}
            />
            <span><label htmlFor={`isActived${index}`}></label></span>
        </div>
    </>
}