import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListZionArea = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    sortExpression && params.append("sorting", sortExpression);
    searchData?.name && params.append("name", searchData.name);
    searchData?.districtId && params.append("districtId", searchData.districtId);
    searchData?.address && params.append("address", searchData.address);
    searchData?.isDeleted!=undefined && params.append("isDeleted", searchData.isDeleted);
    searchData?.isActived != undefined && params.append("isActived", searchData.isActived);
    return service.get(ApiUrl.GetListZionArea, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailZionArea = (id) => {
    return service.get(ApiUrl.GetDetailZionArea.replace("{id}", id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateZionArea = (data) => {
    return service.post(ApiUrl.CreateZionArea, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateZionArea = (data) => {
    return service.put(ApiUrl.UpdateZionArea, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteZionArea = (id) => {
    return service.delete(ApiUrl.DeleteZionArea.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentZionArea = (id) => {
    return service.delete(ApiUrl.DeletePermanentZionArea.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveZionArea = (id) => {
    return service.put(ApiUrl.ActiveZionArea.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveZionArea = (id) => {
    return service.put(ApiUrl.DeActiveZionArea.replace("{id}", id)).then((res) => { return res }).catch(err => { throw err });
}
export const GetLookupZionArea = (departmentId = undefined) => {
    const params = new URLSearchParams();
    departmentId && params.append("departmentId", departmentId);
    return service.get(ApiUrl.GetLookupZionArea, params).then((res) => { return res }).catch((err) => { throw err });
}