import React, { useEffect, useState } from 'react';
import * as swatTrundoManagementAction from "../../../redux/store-sarang/swat-trundo/swat-trundo-management.store";
import { NotificationMessageType, getFestivalCog, formatNumber } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
import * as departmentManagementAction from "../../../redux/store-sarang/department/department-management.store";
import Popover from '@mui/material/Popover';
export default function SwatTrundoHerdsmanRankCupWidget(props) {
    const { userInfo, week, limit, currentFestival } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState(false);
    const [listHerdsmanRank, setListHerdsmanRank] = useState([]);
    const [herdsmanData, setHerdsmanData] = useState();
    const [department, setDepartment] = useState();
    const [getWeek, setGetWeek] = useState(week);

    useEffect(() => {
        getLookupDepartment();
    }, []);

    useEffect(() => {
        getAllListHerdsmanRank();
        console.log(getWeek);
    }, [week, currentFestival]);

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetListNoPermiss();
            if (res && res.content && res.content.items) {
                setDepartment(res.content.items.filter(x=>!x.parentId));
            }
        } catch (err) {
            throw err;
        }
    };

    const getAllListHerdsmanRank = async () => {
        setLoading(true);
        try {
            if (!currentFestival) return;
            const res = await swatTrundoManagementAction.GetListHerdsmanRank(currentFestival?.id, null, week?.week);

            if (res && res.content) {
                setListHerdsmanRank(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    //const GetHerdsmanExaminationReport = async (herdsmanId) => {
    //    setLoading(true);
    //    try {
    //        const res = await examinationAction.GetHerdsmanExaminationReport(herdsmanId, currentFestival?.id, week?.week);

    //        if (res && res.content) {
    //            setHerdsmanData(res.content);
    //        }
    //    } catch (err) {
    //        if (err && err.errorType) {
    //            ShowNotification(
    //                viVN.Errors[err.errorType],
    //                NotificationMessageType.Error
    //            );
    //        }
    //    } finally {
    //        setLoading(false);
    //    }
    //};

    //const herdsmanClick = (event, hdmId) => {
    //    setAnchorEl(event.currentTarget);
    //    GetHerdsmanExaminationReport(hdmId);
    //};

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderExam = (department) => {
        let data = listHerdsmanRank.filter(x => x.departmentId == department.id);
        return data && data.length > 0 && <div className="card">
            <div class="card-header d-flex">
                <h4 class="card-header-title" style={{ fontWeight: 700, color: '#68a1c3' }}>Top cá nhân ban {department.name}</h4>
                <div class="card-header-title ml-auto" style={{ fontWeight: 500, color: 'red' }}>
                    {
                        week ? <> (Tuần {week.week})</> : <> (toàn lễ hội)</>
                    }
                </div>
            </div>
            
            <div className="card-body">
                <div className="row text-center">
                    <div className="col-4">
                        {
                            data.length > 1 && data[1].totalPoint>0 &&
                            <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                //herdsmanClick(event, listHerdsmanRank[1].herdsmanId);
                            }}>
                                <img src="assets/images/rank/person1_top2.png" style={{ width: "40px", paddingTop: "10px" }} />
                                <div style={{ marginTop: "10px", fontWeight: 700 }} >{
                                        data[1].herdsmanName}<br /> &nbsp;
                                        {"(" + data[1].departmentName + ")"
                                    }</div>
                                <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                        formatNumber(data[1].totalPoint)
                                }</div>
                            </div>
                        }
                    </div>
                    <div className="col-4">
                        {

                            data.length > 0 && data[0].totalPoint>0 &&
                            <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                //herdsmanClick(event, listHerdsmanRank[0].herdsmanId);
                            }}>
                                <img src="assets/images/rank/person1_top1.png" style={{ width: "50px" }} />
                                <div style={{ marginTop: "10px", fontWeight: 700 }} >{
                                        data[0].herdsmanName}<br /> &nbsp;
                                        {" (" + data[0].departmentName + ")"
                                    }</div>
                                <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                        formatNumber(data[0].totalPoint)
                                }</div>
                            </div>
                        }
                    </div>
                    <div className="col-4">
                        {
                            data.length > 2 && data[2].totalPoint>0 &&
                            <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                //herdsmanClick(event, listHerdsmanRank[2].herdsmanId);
                            }}>
                                <img src="assets/images/rank/person1_top3.png" style={{ width: "35px", paddingTop: "15px" }} />
                                <div style={{ marginTop: "10px", fontWeight: 700 }} >{
                                        data[2].herdsmanName}<br /> &nbsp;
                                        {" (" + data[2].departmentName + ")"
                                    }</div>
                                <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                        formatNumber(data[2].totalPoint)
                                }</div>
                            </div>
                        }
                    </div>

                </div>
                <div className="row text-center">
                    <table className="table dataTable table-hover " id="datatable">
                        <thead>
                            <tr>
                                <td style={{ minWidth: "30px" }}><b>Hạng</b></td>
                                <td style={{ minWidth: "100px" }}><b>Họ tên</b></td>
                                <td style={{ minWidth: "70px" }}><b>Địa vực</b></td>
                                <td style={{ minWidth: "30px" }}><b>Điểm</b></td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.map((item, index) =>
                                    index > 2 && index <= limit - 1 && item.totalPoint>0 &&
                                    <tr>
                                        <td>{item.rank}</td>
                                        <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                            //herdsmanClick(event, item.herdsmanId);
                                        }}>{item.herdsmanName}</td>
                                        <td>{item.departmentName}</td>
                                        <td>{formatNumber(item.totalPoint)}</td>
                                    </tr>

                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <LoadingViewAria loading={loading} />
        </div>
    }

    return (
        <>
            {
                //<Popover
                //    id='buoc1'
                //    open={open}
                //    anchorEl={anchorEl}
                //    onClose={handleClose}
                //    anchorOrigin={{
                //        vertical: 'bottom',
                //        horizontal: 'left',
                //    }}
                //>
                //    {/*<Typography sx={{ p: 2 }}>test</Typography>*/}
                //    {
                //        herdsmanData?.rP_Examination_Report_Dtos && herdsmanData?.rP_Examination_Report_Dtos.length > 0 &&
                //        <table className="table dataTable">
                //            <thead>
                //                <tr >
                //                    <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                //                    <td style={{ minWidth: "50px" }}><b>#</b></td>
                //                    <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                //                </tr>
                //            </thead>
                //            <tbody>
                //                {
                //                    herdsmanData?.rP_Examination_Report_Dtos.map((itemherdsman, index) =>
                //                        <tr>
                //                            <td>{optionsExamination.find(x => x.value == itemherdsman.status)?.label}</td>
                //                            <td>{itemherdsman.quantity}</td>
                //                            {/*<td>{Math.round((itemherdsman.quantity * itemherdsman.point) * 100) / 100}</td>*/}
                //                            <td>{Math.round((itemherdsman.point) * 100) / 100}</td>
                //                        </tr>)
                //                }
                //            </tbody>
                //        </table>
                //    }
                //</Popover>
            }
            <div className="row">
                
                {
                    department && department.map((dept, index) => (
                        listHerdsmanRank.filter(x => x.departmentId == dept.id).length > 0 &&
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            {renderExam(dept)}
                        </div>
                    ))
                }
            </div>
        </>
    )
}