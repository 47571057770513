import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import DepartmentTreeSelect from './department-tree-select';
import DepartmentHelper from '../helper/department-helper';
import { patternNumber } from '../../../common/validatePhoneNumber';

export default function FormAddEditDepartment(props) {
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        options,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);
    
    const [parentsCode, setParentsCode] = useState("");
    const [itemCode, setItemCode] = useState("");

    const [checkActive, setCheckActive] = useState(true);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        console.log("updateItem",updateItem)
        // Lấy code split ra, item cuối là code của bản ghi, phần còn lại là code của parent
        const codeSplit = updateItem?.code?.split(".") || [];
        if (codeSplit.length > 1) {
            setItemCode(codeSplit.pop());
            setParentsCode(codeSplit.join('.'));
        } else if (codeSplit.length == 1) {
            setItemCode(codeSplit[0])
        }
        setCheckActive(updateItem?.isActived);
        setValue("name", updateItem?.name);
        setValue("code", updateItem?.code);
        setValue("level", updateItem?.level);
        setValue("description", updateItem?.description);
        setValue("parentId", updateItem?.parentId);
    }, [])

    // Default select parent trong dropdown khi bật modal edit bản ghi
    useEffect(() => {
        
        setSelected(options.find(item => item.code === parentsCode))
    }, [parentsCode, itemCode])

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }
        
        onSubmitAddEdit({...data,
            parentId: updateItem?.parentId ? updateItem?.parentId : (data.parentId || null),
            // code: selected ? parentsCode + "." + itemCode : itemCode, // Backend tự gen code rồi nên ko cần input vào nữa
            level: data.level || 1,
            id: updateItem?.id,
        });
        
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Tên địa vực <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={updateItem?.name}
                        placeholder="Tên địa vực"
                        ref={register({ required: true })}
                    />
                    {errors.name && errors.name.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>SL thánh đồ</label>
                    <div className="input-group mb-3">
                        <input
                            className="form-control"
                            type="text"
                            name="memberCount"
                            placeholder="SL thánh đồ"
                            ref={register()}
                            
                            defaultValue={updateItem?.memberCount}
                            onChange={(e) => {
                                setValue("memberCount", e.target.value.replace(patternNumber, "") != "" ? e.target.value.replace(patternNumber, "") : 0);
                            }}
                        />
                    </div>
                    {/*<div className="input-group mb-3">*/}
                    {/*    {*/}
                    {/*        parentsCode && */}
                    {/*        <span className="input-group-prepend">*/}
                    {/*            <span className="input-group-text">{parentsCode + "."}</span>*/}
                    {/*        </span>*/}
                    {/*    }*/}
                    {/*    <input*/}
                    {/*        className="form-control"*/}
                    {/*        type="text"*/}
                    {/*        name="code"*/}
                    {/*        placeholder="Mã địa vực"*/}
                    {/*        // ref={register({ required: true })}*/}
                    {/*        value={itemCode}*/}
                    {/*        readOnly*/}
                    {/*        onChange={(e) => setItemCode(e.target.value)}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="row">
                <div className="form-group col-md-6">
                    <label>Mô tả</label>
                    <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={updateItem?.description}
                        placeholder="Mô tả"
                        ref={register()}
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Thuộc ban</label>
                       
                    <DepartmentTreeSelect
                        {...register("parentId")}
                        onChange={(data) => {
                            setValue("level", data ? `${parseInt(data.level, 10) + 1}` : null);
                            setValue("parentId", data ? data.value : null);
                            setParentsCode(data ? data.code : null);
                            // setSelected(data || null);
                        }}
                        // selected={selected}
                        selected={options?.find(item => item.value === updateItem?.parentId)}
                            options={updateItem ? options?.map(item => {
                                if (item.value == updateItem.id) {
                                    return {
                                        ...item, isDisabled: true
                                    }
                                }

                                if (item.parentId == updateItem.id) {
                                    return {
                                        ...item, isDisabled: true
                                    }
                                }

                                return item
                            }) : options}
                        isDisabled={updateItem?.id }
                        // options={options}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Trạng thái hoạt động</label>
                    <div className="switch-button switch-button-yesno ml-5">
                        <input
                            type="checkbox"
                            checked={checkActive}
                            name="isActived"
                            id="isActived"
                            ref={register()}
                            onChange={() => setCheckActive(prev => !prev)}
                        />
                        <span><label htmlFor="isActived"></label></span>
                    </div>
                </div>
            </div>

            <input name="level" ref={register()} style={{display: 'none'}}/>
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}


// TODO : Search trong option của department mới lấy mỗi bản ghi match với input => Cần bổ sung thêm logic nếu search cha thì ko sao, chứ search con thì phải hiện cả cha