import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as swatTrundoManagementAction from "../../redux/store-sarang/swat-trundo/swat-trundo-management.store";

import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import Select from "react-select";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as styles from "../../components/form-search-wrapper-sarang/form-search-wrapper-sarang.module.scss";
import { patternNumber } from '../../common/validatePhoneNumber';
import { useMediaQuery } from 'react-responsive';
import { NumericFormat } from 'react-number-format';
import { permission } from '../../common/constant';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "reportDate desc",
    orderBy: "reportDate",
    order: "desc",
}

export default function SwatTrundoInput() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    const [openSearch, setOpenSearch] = useState(false);

    // -- End Data to map with ids

    const userInfo = getUserInfo();
    const [data, setData] = useState([]);
    const [searchData, setSearchData] = useState();
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);

    const [isOpenAddEditDialog, setOpenAddEdit] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);


    useEffect(() => {
        //getListByDate(dayjs(new Date()));
    }, []);

    const getListByDate = async (date=undefined) => {
        showLoading(true);
        try {
            const res = await swatTrundoManagementAction.GetListByDate(date);
    
            if (res && res.content) {
                setData(res.content);
                setTotalItemCount(res.content.length);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    //const handleRequestSort = (event, property) => {
    //    const isAsc = orderBy === property && order === 'asc';
    //    setOrder(isAsc ? 'desc' : 'asc');
    //    setOrderBy(property);
    //    let sort = isAsc ? 'desc' : 'asc';
    //    let sortExpression = property + ' ' + sort;
    //    getListSwatActivityReportManagement(page + 1, rowsPerPage, sortExpression, searchData);
    //};
    
    //const handleChangePage = (newPage) => {
    //    setPage(newPage - 1);
    //    let sortExpression = orderBy + ' ' + order;
    //    getListSwatActivityReportManagement(newPage, rowsPerPage, sortExpression, searchData);
    //};
    
    //const handleChangeRowsPerPage = (event) => {
    //    setRowsPerPage(parseInt(event.target.value, 10));
    //    setPage(0);
    //    let sortExpression = orderBy + ' ' + order;
    //    getListSwatActivityReportManagement(1, event.target.value, sortExpression, searchData);
    //};
    
    
    const buttonOpenConfirmRef = useRef();

    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        //setOpenConfirmDialog(false);
        //setSelectedItem(null);
    }

    const handleConfirm = async () => {
        showLoading(true);
        try {
            let res = null;

          if (isOpenConfirmDialog) {
                //res = await swatReportManagementAction.ChangeConfirm({ id: selectedItem.id, value: true });
                //if (res && res.content && res.content.status) {
                //    ShowNotification(
                //        viVN.Success["UpdateSuccess"],
                //        NotificationMessageType.Success
                //    );
                //}
            }

            //if (res) {
            //    let sortExpression = orderBy + ' ' + order;
            //    getListSwatActivityReportManagement(page + 1, rowsPerPage, sortExpression, searchData);
            //}

            closeConfirmDialog();
        } catch (err) {
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }

    const onSubmit =async (data1) => {
        //setSearchData(data);
        //let sortExpression = orderBy + ' ' + order;
        //setPage(0);
        //getListSwatActivityReportManagement(1, rowsPerPage, sortExpression, data);
        //if (isTabletOrMobile)
        //    setOpenSearch(false);

        showLoading(true);

        try {
            let res = null;
            if (data && data.length > 0) {
                res = await swatTrundoManagementAction.CreateOrUpdate(data);
                if (res && res.content) {
                    ShowNotification(
                        viVN.Success["UpdateSuccess"],
                        NotificationMessageType.Success
                    );
                }
            }
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }

    }

    const onSubmitOneRow = async (data1) => {
        //setSearchData(data);
        //let sortExpression = orderBy + ' ' + order;
        //setPage(0);
        //getListSwatActivityReportManagement(1, rowsPerPage, sortExpression, data);
        //if (isTabletOrMobile)
        //    setOpenSearch(false);

        showLoading(true);

        try {
            let res = null;
            let saveData = new Array();
            saveData.push(data1);
            res = await swatTrundoManagementAction.CreateOrUpdate(saveData);

            if (res && res.content) {
                ShowNotification(
                    viVN.Success["UpdateSuccess"],
                    NotificationMessageType.Success
                );
            }
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }

    }

    const renderListItemForMobile = () => {
        return <div className="row">
            {
                data && data.map((item, index) => (
                    <div className="col-12 col-md-4 col-lg-4 col-xl-4" key={index}>
                        <div className="card">
                            <div className="card-header  d-flex">
                                <h5><b>{index + 1}.{item.herdsmanName}</b></h5>
                                <div className="dropdown ml-auto">
                                    <a className="toolbar" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        onSubmitOneRow(item);
                                    }} role="button" aria-haspopup="true" aria-expanded="false"><i title="Lưu" className="fas fa-save"></i> </a>
                                </div> 
                            </div>
                            
                            <div className="card-body row">
                                {
                                    item.statusQuantities && item.statusQuantities.map((itemStatus, itemIndex) => (
                                        <div className="form-group col-6" key={itemIndex}>
                                            <label>{itemStatus.statusName}</label>
                                            <CreateInput item={item} itemStatus={itemStatus} index={index} userInfo={userInfo } />
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    }
    const renderTableForDesktop = () => {
        return data && data.length > 0 && <div className="row">
            <div className="card">
                <div className="card-body">
                    <table className="table dataTable table-hover " id={`datatableDetail`}>
                        <thead>
                            <th style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle',  minWidth: '150px'}}>Họ tên</th>
                            {
                                data && data.length > 0 && data[0].statusQuantities && data[0].statusQuantities.map((item, index) => (
                                    <th key={index} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle' }}>{item.statusName}</th>
                                ))
                            }
                            <th></th>
                        </thead>
                        <tbody>
                            {
                                data && data.map((item, index) => (
                                    <tr key={index }>
                                        <td>{item.herdsmanName}
                                            {
                                                item.wMteamId && <> (WM)</>
                                            }
                                        </td>
                                        {
                                            item.statusQuantities && item.statusQuantities.map((itemStatus, itemIndex) => (
                                                <td key={itemIndex}>
                                                    <CreateInput item={item} itemStatus={itemStatus} index={index} userInfo={userInfo} />
                                                </td>
                                            ))
                                        }
                                        <td>
                                            <button className="d-inline-block btn btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Lưu"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    onSubmitOneRow(item);
                                                }}
                                            >
                                                <i className="fas fa-save"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }
    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card">
                    <div className="card-body p-2 p-md-4">
                        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                            <div className="row">
                                <div className="form-group col-6">
                                    <DatePicker
                                        {...register("reportFromDate")}
                                        onChange={(data) => {
                                            setValue("reportFromDate", dayjs(data).format());
                                            //setSearchData({ ...searchData, reportFromDate: dayjs(data).format() });
                                            getListByDate(dayjs(data).format());
                                        }}
                                        //defaultValue={dayjs(new Date())}
                                        //value={searchData?.reportFromDate ? dayjs(searchData?.reportFromDate) : null}
                                        className="form-control"
                                        format='DD/MM/YYYY'
                                        slotProps={{ textField: { size: 'small' } }}
                                        label={"Chọn ngày"}
                                    />
                                </div>
                                <div className="col-6 pl-0">
                                    <p className="text-center">
                                        <button type="submit" className="btn btn-space btn-primary">Lưu tất cả</button>
                                    </p>
                                </div>

                            </div>
                            {
                                /*isDesktopOrLaptop ? <RenderDataDesktop onSubmit={onSubmit} onSubmitOneRow={onSubmitOneRow} data={data } /> : <></>*/
                                isDesktopOrLaptop ? renderTableForDesktop() : <></>
                            }

                            {
                                isTabletOrMobile ? renderListItemForMobile() : <></>
                            }
                        </form>
                         
                    </div>
                </div>
            </div>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>
            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa bản ghi này không?" :
                    isOpenActiveDialog ? "Bạn có chắc chắn muốn mở khóa bản ghi này không?" :
                    isOpenDeactiveDialog ? "Bạn có chắc chắn muốn khóa bản ghi này không?" : 
                    isOpenConfirmDialog ? "Bạn có chắc chắn muốn xác nhận bản ghi này không?" : ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog || isOpenConfirmDialog}
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}

const CreateInput = (props) => {
    const { item, itemStatus, index, userInfo } = props;
    const { setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const [ enabled, setEnabled ] = useState(true);
    const [ showNumberBox, setShowNumberBox ] = useState(true);
    useEffect(() => {
        setStatusQtt(itemStatus.quantity);

    }, [itemStatus.quantity]);

    useEffect(() => {
        setEnabled(
            (userInfo && (
                userInfo.userRoles.includes(permission.Admin) ||
                userInfo.userRoles.includes(permission.Chapsu)
            )) ||
            (!item.isNotMyWM && userInfo && userInfo.userRoles.includes(permission.Wmleader) && (itemStatus.statusId == 210 || itemStatus.statusId == 220) && item.wMteamId) ||
            ((!item.isNotMyArea && userInfo && (item.herdsmanId === 1022 || item.herdsmanId === 1025 || item.herdsmanId === 1026 ||
                userInfo.userRoles.includes(permission.Diavuctruong) ||
                userInfo.userRoles.includes(permission.Dvt_hn) ||
                userInfo.userRoles.includes(permission.Khuvuctruong) ||
                userInfo.userRoles.includes(permission.Kvt_hn)
            )) && itemStatus.statusId <= 190)
        );
    }, [itemStatus.statusId])
    const [statusQtt, setStatusQtt] = useState(null);
    
    return <>
        {/*
            <input
        className="form-control"
        type="text"
        name={`quantity${item.herdsmanId}[${index}][${itemStatus.statusIdnpm run build}]`}
        value={statusQtt}
        placeholder={itemStatus.statusName}

        onChange={(e) => {
            itemStatus.quantity = e.target.value.replace(patternNumber, "");

            if (itemStatus.quantity === "") itemStatus.quantity = 0
            setStatusQtt(itemStatus.quantity);
        }
        }
    />
            */ }
        
        <NumericFormat
            className="form-control"
            name={`quantity${item.herdsmanId}[${index}][${itemStatus.statusId}]`}
            onValueChange={(values, sourceInfo) => {
                /*console.log('onValueChange', values, sourceInfo);*/
                console.log('onValueChange', values.value, sourceInfo);
                itemStatus.quantity = values.value == "" ? 0 : values.value;
                setStatusQtt(itemStatus.quantity);
            }}
            readOnly={!enabled }
            value={statusQtt}
            decimalScale={2}
            decimalSeparator="," />
    </>
}