import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListZionArea from './head-cell-list-zion-area';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as zionAreaManagementAction from "../../redux/store-sarang/zion-area/zion-area-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import DepartmentHelper from '../list-department/helper/department-helper';

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';

import FormAddEditZionArea from './components/form-add-edit-zion-area';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function ZionAreaList() {
    const { register, handleSubmit, setValue } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids

        // -- End Data to map with ids

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);
    const [searchData, setSearchData] = useState();
    const [department, setDepartment] = useState([]);
    const [userInfo, setUserInfo] = useState(getUserInfo());

    useEffect(() => {
        getLookupDepartment();
        getListZionArea();
    }, []);

    const getListZionArea = (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, search=undefined) => {
        showLoading(true);
        // setPage(pageIndex-1)
        zionAreaManagementAction.GetListZionArea(pageIndex, pageSize, sortExpression, search).then(
            (res) => {
                if (res &&
                    res.content &&
                    res.content.items
                ) {
                    setData(res.content.items)
                    setTotalItemCount(res.content.totalItemCount)
                }
                showLoading(false);
            },
            (err) => {
                showLoading(false);
                err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        );
    }

    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(DepartmentHelper.RenderOptions(DepartmentHelper.PrepareSortData(res.content.items)));
            }
        } catch (err) {
            throw err;
        }
    };
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListZionArea(page + 1, rowsPerPage, sortExpression, searchData);
        console.log(sortExpression);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListZionArea(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListZionArea(1, event.target.value, sortExpression, searchData);
    };

    const handleSubmitAddEdit = async (data) => {
        showLoading(true);
        
        try {
            let res = null;

            if (!data.id) {
                res = await zionAreaManagementAction.CreateZionArea(data);
            } else {
                res = await zionAreaManagementAction.UpdateZionArea(data);
            }

            if (res && res.content) {
                if (!data.id)
                    ShowNotification(
                        viVN.Success["CreateSuccess"],
                        NotificationMessageType.Success
                    );
                else
                    ShowNotification(
                        viVN.Success["UpdateSuccess"],
                        NotificationMessageType.Success
                    );
                let sortExpression = orderBy + ' ' + order;
                getListZionArea(page + 1, rowsPerPage, sortExpression, searchData);
            }
            showLoading(false);
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);

    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }
    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }
    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setSelectedItem(null);
    }
    const handleConfirm = async () => {
        try {
            let res = null;

            if (isOpenDeleteDialog) {
                res = await zionAreaManagementAction.DeleteZionArea(selectedItem.id);
                if (res)
                ShowNotification(
                    viVN.Success["DeleteSuccess"],
                    NotificationMessageType.Success
                );
            } else if (isOpenActiveDialog) {
                res = await zionAreaManagementAction.DeActiveZionArea(selectedItem.id);
                if (res)
                ShowNotification(
                    viVN.Success["ActiveSuccess"],
                    NotificationMessageType.Success
                );
            } else if (isOpenDeactiveDialog) {
                res = await zionAreaManagementAction.DeActiveZionArea(selectedItem.id);
                if (res)
                ShowNotification(
                    viVN.Success["DeActiveSuccess"],
                    NotificationMessageType.Success
                );
            }

            if ( res ) {
                getListZionArea();
            }
            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    const onSubmit = (data) => {
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order;
        setPage(0);
        getListZionArea(1, rowsPerPage, sortExpression, data);
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <div className="form-group">
{/*                                            <label htmlFor="input-name" className="col-form-label">Họ và tên</label>*/}
                                            <input
                                                id="name"
                                                className="form-control"
                                                type="text"
                                                name="name"
                                                defaultValue={searchData?.name}
                                                placeholder="Nhập tên để tìm kiếm"
                                                ref={register()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6 pl-0">
                                        <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                        <button className="btn btn-space btn-secondary" onClick={(e) => {
                                            setValue("name","");
                                        }}>Xóa lọc</button>
                                        <button
                                            className="btn btn-space btn-warning"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                openAddDialog();
                                            }}
                                        >Thêm mới</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </form>
                <DataTableSarang
                    // button functions
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    // head cells
                    headCells={headCellsListZionArea}
                    handleRequestSort={handleRequestSort}
                    order={order}
                    orderBy={orderBy}
                    // pagination
                    totalItemCount={totalItemCount}
                    setRowsPerPage={setRowsPerPage}
                    pageIndex={page}
                    handleChangePage={handleChangePage}
                >
                    <tbody>
                        {
                            data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td><span>{row.name}</span></td>
                                    <td><span>{row.memberCount}</span></td>
                                    <td><span>{row.departmentName}</span></td>
                                    <td><span>{row.description}</span></td>
                                    <td align="center">
                                        <span>
                                            {row.isActived ? (
                                                <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick" onClick={() => {
                                                        setOpenDeactiveDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                                />
                                            ) : (
                                                <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../assets/icon/cancel.png")}
                                                        alt="Cancel" onClick={() => {
                                                            setOpenActiveDialog(true);
                                                            openConfirmDialog(row);
                                                        }}
                                                />
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Sửa"
                                                onClick={() => {openEditDialog(row)}}
                                            >
                                                <i className="far fa-edit"></i>
                                            </button>
                                            {(userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN"))) &&
                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                    title="Xóa"
                                                    onClick={() => {
                                                        setOpenDeleteDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                                >
                                                    <i className="far fa-trash-alt"></i>
                                                </button>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                        }
                    </tbody>
                </DataTableSarang>
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>
            <ModalSubmitForm
                title={!selectedItem ? "Thêm mới địa vực" : "Chỉnh sửa địa vực"}
                open={isOpenAddEditDialog}
                onClose={closeAddEditDialog}
            >
                <FormAddEditZionArea
                    // ===
                    updateItem={selectedItem}
                    onSubmitAddEdit={handleSubmitAddEdit}
                    department={department }
                />
            </ModalSubmitForm>

            <ModalConfirm 
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Ae/Ce có chắc chắn muốn xóa bản ghi này không?":
                    isOpenActiveDialog ? "Ae/Ce có chắc chắn muốn mở khóa bản ghi này không?":
                    isOpenDeactiveDialog ? "Ae/Ce có chắc chắn muốn khóa bản ghi này không?": ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog }
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}