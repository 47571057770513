import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as appActions from "../../core/app.store";
import * as config from '../../common/config'
import Select from "react-select";
import Popover from '@mui/material/Popover';

import headCellsListHerdsman from './head-cell-list-herdsman';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, getUserInfo, sendReactGaEvent } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as zionManagementAction from "../../redux/store-sarang/zion/zion-management.store";
import * as zionAreaManagementAction from "../../redux/store-sarang/zion-area/zion-area-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as groupManagementAction from "../../redux/store-sarang/group/group-management.store";
import * as districtManagementAction from "../../redux/store/district-management/district.store"
import * as positionManagementAction from "../../redux/store-sarang/position/position-management.store";
import * as wmteamManagementAction from "../../redux/store-sarang/wmteam/wmteam-management.store";
import * as userManagementAction from "../../redux/store/user-management/user-management.store";
import * as feedbackManagementAction from "../../redux/store-sarang/feedback/feedback-management.store";
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import dayjs from 'dayjs';

import DepartmentHelper from '../list-department/helper/department-helper';
import cleanAccents from '../../common/replace';
import DepartmentTreeSelect from '../list-department/components/department-tree-select';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function HerdsmanWMList() {
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });
    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));

    // -- Data to map with ids
    const userInfo = getUserInfo();
    const [wMTeam, setWMTeam] = useState([]);
    const [user, setUser] = useState();
    const [searchData, setSearchData] = useState();
    const [openSearch, setOpenSearch] = useState(false);

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);

    const buttonOpenAddEditRef = useRef();
    const buttonOpenConfirmRef = useRef();
        const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenUpgradeDialog, setOpenUpgradeDialog] = useState(false);
    const [isOpenDowngradeDialog, setOpenDowngradeDialog] = useState(false);
    const [isOpenCreateAccountDialog, setOpenCreateAccountDialog] = useState(false);
    const [isOpenAlertUserName, setOpenAlertUserName] = useState(false);
    const [isOpenSendMessage, setOpenSendMessage] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

 
    const getLookupWMTeam = async () => {
        try {
            const res = await wmteamManagementAction.GetLookupWMTeam();
            if (res && res.content) {
                setWMTeam(res.content);
            }
        } catch (err) {
            throw err;
        }
    };
    
    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupWMTeam(),
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }

    // -- End Data to map with ids

    useEffect(() => {
        getListHerdsmanManagement();
        fetchData();
    }, []);

    const getListHerdsmanManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, searchData=undefined) => {
        showLoading(true);
        try {
            const res = await herdsmanManagementAction.GetListHerdsmanWM(pageIndex, pageSize, sortExpression, searchData);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };
    
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListHerdsmanManagement(page + 1, rowsPerPage, sortExpression, searchData);
        //console.log(sortExpression);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListHerdsmanManagement(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListHerdsmanManagement(1, event.target.value, sortExpression, searchData);
    };

    const handleSubmitAddEditSwat = async (data) => {
        showLoading(true);
        
        let formData = new FormData();
        //console.log(data);
        for (let key in data) {
            // false hoặc 0 vẫn append vào form
            if(data[key] !== undefined && data[key] !== null && data[key] !== '') formData.append(key, data[key]);
        }

        try {
            let res = null;

            if( !data.id ) {
                res = await herdsmanManagementAction.CreateHerdsman(formData);
            } else {
                res = await herdsmanManagementAction.UpdateHerdsman(formData);
            }
            if ( res && res.content ) {
                let sortExpression = orderBy + ' ' + order;
                getListHerdsmanManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
            showLoading(false);
            ShowNotification(
                viVN.Success["UpdateSuccess"],
                NotificationMessageType.Success
            );
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }

    
    const [selectedItem, setSelectedItem] = useState(null);
    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };
    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const openSendMessageDialog = (item) => {
        setOpenSendMessage(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }
    const closeSendMessageDialog = (item) => {
        setOpenSendMessage(false);
        setSelectedItem(null);
    }
    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setSelectedItem(null);
    }

    const closeAlertDialog = () => {
        setOpenAlertUserName(false);
    }

    const onSubmit = (data) => {
        //console.log(data);
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order;
        setPage(0);
        getListHerdsmanManagement(1, rowsPerPage, sortExpression, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }


    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog} hideNewButton={true }
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsListHerdsman}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                        data.map((row, rowIndex) => (
                            <tr key={rowIndex} style={row.isCaptain ? { backgroundColor: 'lightgrey' } : {}}>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? {fontWeight:700} : {} }
                                ><span>{row["gender"] ? <li className="fas fa-male text-primary"></li> : <li className="fas fa-female text-secondary"></li>} {`${row["firstName"]} ${row["lastName"]}`}</span>
                                    {userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) && row.chatTelegramId &&
                                    <span>&nbsp;<i className="fab fa-telegram-plane mr-2 text-info" title="Gửi tin nhắn" style={{ cursor: 'pointer' }} onClick={() => {
                                        setOpenSendMessage(true);
                                        openSendMessageDialog(row);
                                    } }></i></span>}
                                </td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span>{row.departmentName}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.zionAreaName}</span></td>
                                <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.wmTeamName}</span></td>
                                <td><span>{row["description"]}</span></td>
                                <td>
                                    <span>
                                        {row.isActived ? (
                                            <img title="Đang kích hoạt" src={require("../../assets/icon/tick.png")}alt="Tick"/>
                                        ) : (
                                            <img title="Ngưng kích hoạt" src={require("../../assets/icon/cancel.png")}/>
                                        )}
                                    </span>
                                </td>
                                <td>
                                    
                                </td>
                            </tr>
                        )) :
                        <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <div id="accordion">
                    <div className="card">
                        <div className="d-flex align-items-center">
                            <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                data-toggle="collapse" aria-expanded="false" >
                                <div className="d-flex w-100">
                                    <label className='d-flex align-items-end justify-content-end' style={{ gap: "10px" }}>
                                        Xem
                                        <select
                                            className="custom-select custom-select-sm form-control form-control-sm"
                                            style={{ width: "50px" }}
                                            value={rowsPerPage}
                                            onChange={handleChangeRowsPerPage}
                                        >
                                            {config.Configs.DefaultPageSizeOption.map((pageSize, index) => (
                                                <option key={index} value={pageSize}>{pageSize}</option>
                                            ))}
                                        </select>
                                        mục
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        data.length > 0 ?
                        data.map((item, index) => (
                            <div className="card" key={index}>
                                <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                    <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{border: "none"}}
                                        data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                                        <div className="d-flex w-100 justify-content-between">
                                            <h5 className="mb-1">{`${item["firstName"]} ${item["lastName"]}`}</h5>
                                            
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <div className='mt-0'><b>Địa vực:</b></div>
                                                <span>{item.departmentName}</span><br />
                                                <div className='mt-0'><b>Khu vực:&nbsp;</b>
                                                    <span>{item.zionAreaName}</span>
                                                </div>
                                            </div>
                                            <div className='col-6'>
                                                <div className='mt-0'><b>WM:&nbsp;</b>
                                                    <span>{item.wmTeamName}</span>
                                                </div>
                                                <div className='mt-0'>
                                                    <b>Hoạt động: </b>
                                                    <span>
                                                        {item.isActived ? (
                                                            <img title="Đang kích hoạt" src={require("../../assets/icon/tick.png")} alt="Tick" />
                                                        ) : (
                                                            <img title="Ngưng kích hoạt" src={require("../../assets/icon/cancel.png")}alt="Cancel" />
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                    <DataTablePagination
                        totalItemCount={totalItemCount}
                        rowsPerPage={rowsPerPage}
                        pageIndex={page + 1}
                        handleChangePage={handleChangePage}
                    /> :
                    <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog} hideNewButton={true }
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderFormSearch = () => {
        return <>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row">
                    <div className="form-group col-md-3 col-sm-6">
                        <div className="form-group">
                            <label htmlFor="input-name" className="col-form-label">Họ tên</label>
                            <input
                                id="name"
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Nhập họ tên"
                                ref={register()}
                            />
                        </div>
                    </div>
                    <div className="form-group col-md-3  col-sm-6">
                        <div className="form-group">
                            <label htmlFor="input-phoneNumber" className="col-form-label">Điện thoại</label>
                            <input
                                id="phoneNumber"
                                className="form-control"
                                type="text"
                                name="phoneNumber"
                                placeholder="Số điện thoại"
                                ref={register()}
                            />
                        </div>
                    </div>
                    {wMTeam ? <div className="form-group col-md-3 col-sm-6">
                        <label>WM</label>
                        <Select
                            isClearable
                            {...register("wMTeamId")}
                            placeholder="WM"
                            closeMenuOnSelect={true}
                            onChange={(data) => setValue("wMTeamId", data ? data.value : null)}
                            options={wMTeam.map(item => { return { label: item.name, value: item.id } })}
                            noOptionsMessage={() => "Không tồn tại"}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                        :
                        <></>
                    }
                    
                </div>
                <FormSearchFunctionButtons>
                    <div className="row">
                        <div className="col-sm-12 pl-0">
                            <p className="text-center">
                                <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                                <button className="btn btn-space btn-secondary">Xóa lọc</button>
                            </p>
                        </div>
                    </div>
                </FormSearchFunctionButtons>
            </form>
        </>
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {
                        isDesktopOrLaptop ? renderTableForDesktop() : <></>
                    }

                    {
                        isTabletOrMobile ? renderListItemForMobile() : <></>
                    }
                </div>
                <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                    Launch modal add edit
                </button>
                <ModalSubmitForm
                    title={isOpenSendMessage ? "Nhắn tin cho ace" : (!selectedItem ? "Thêm mới ace" : "Chỉnh sửa thông tin ace")}
                    open={isOpenAddEditDialog || isOpenSendMessage}
                    onClose={!isOpenSendMessage ? closeAddEditDialog : closeSendMessageDialog}
                >
                    {
                        
                    }
                </ModalSubmitForm>
            </div>
        </>
        
    )
}