import React, { useEffect, useState, useRef } from 'react';
import SheepNumberWidget from './component/dashboard-sheep-number/sheep-number-widget';
import SwatTrundoSumaryWidget from './component/swat-trundo/swat-trundo-sumary-widget';
import SwatTrundoHerdsmanRankCupWidget from '../examination-dashboard/swat-trundo/swat-trundo-herdsman-rank-cup-widget';
import SwatTrundoDepartmentRankCupWidget from '../examination-dashboard/swat-trundo/swat-trundo-department-rank-cup-widget';
import HMAcademyDepartmentRankCupWidget from '../examination-dashboard/academies/swat-academy-department-rank-cup-widget';
import SwatTrundoZionAreaRankCupWidget from '../examination-dashboard/swat-trundo/swat-trundo-zion-area-rank-cup-widget';
import SwatTrundoWMTeamRankCupWidget from '../examination-dashboard/swat-trundo/swat-trundo-wmteam-rank-cup-widget';
import IntroduceWidget from './component/dashboard-introduce/introduce-number-widget';
import SheepNumberChart from './component/dashboard-sheep-number/sheep-number-chart';
import CookingRegisterChart from './component/dashboard-cooking-register/cooking-register-chart';
import TransportationChart from './component/dashboard-transport/transport-chart';
import SheepAnuallyReport from './component/dashboard-sheep-number/sheep-anually_report';
import { getUserInfo } from '../../utils/configuration';
import ReactPlayer from 'react-player/lazy';
import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";
import * as examinationAction from "../../redux/store-sarang/examination-report/examination-report-management.store";
import { getFestival } from '../../common/profileModules';

export default function DashboardHome() {
    //var userRole: string;
    const userInfo = getUserInfo();
    const listMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const limit = 10;
    const [week, setWeek] = useState(null);
    const [currentWeek, setCurrentWeek] = useState();
    const [currentFestival, setCurrentFestival] = useState();

    useEffect(() => {
        GetCurrentWeek();
        GetCurrentFestival();
        setTimeout(() => {
            const elements = document.getElementsByClassName("highcharts-credits");
            const elementsArray = Array.from(elements);
            
            elementsArray.forEach(element => {
                element.style.display = "none";
            });
        }, 100);
    }, [])

    const GetCurrentWeek = async () => {
        try {
            const res = await examinationAction.GetCurrentWeek();

            if (res && res.content) {
                setCurrentWeek(res.content);
            }
        } catch (err) {
            ;

        } finally {
            ;
        }
    };

    const GetCurrentFestival = async () => {
        try {
            const res = await getFestival();

            setCurrentFestival(res);
        } catch (err) {
            ;

        } finally {
            ;
        }
    };

    return (
        <>
            <div className="row">
                <SwatTrundoSumaryWidget/>
            </div>
            <div className="row card" style={{ backgroundColor: '#68a1c3' }}>
                {/*<h5 className="card-header" style={{ backgroundColor: '#68a1c3', color: 'white', fontWeight: 'bold' }}>LH HOAN HỈ CHUYỂN NHÀ</h5>*/}
                <div class="card-header d-flex" style={{ backgroundColor: '#68a1c3', fontWeight: 'bold' }}>
                    <h4 class="card-header-title" style={{ color: 'white'}}>LH HOAN HỈ CHUYỂN NHÀ</h4>
                    <select class="custom-select ml-auto w-auto" defaultValue={ 2} onChange={(e) => {
                        if (e.target.value == 1) {
                            setWeek(currentWeek);
                        }
                        else {
                            setWeek();
                        }
                    }}>
                        <option value={1 }>Tuần</option>
                        <option value={2}>Toàn lễ hội</option>
                    </select>
                </div>
                <div className="card-body">
                    <SwatTrundoHerdsmanRankCupWidget
                        limit={limit}
                        week={week}
                        currentFestival={currentFestival }
                    />
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <SwatTrundoZionAreaRankCupWidget
                                limit={limit}
                                week={week}
                                currentFestival={currentFestival }
                            />
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <SwatTrundoDepartmentRankCupWidget
                                limit={limit}
                                week={week}
                                currentFestival={currentFestival}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card" style={{ backgroundColor: '#68a1c3' }}>
                        <h5 className="card-header" style={{ backgroundColor: '#68a1c3', color: 'white', fontWeight: 'bold' }}>LH RUNNING 7</h5>
                        <div className="card-body">
                            {/*<div className="row">*/}
                            {/*    <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                            {/*<SwatTrundoWMTeamRankCupWidget*/}
                            {/*    limit={12}*/}
                            {/*/>       */}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <SwatTrundoWMTeamRankCupWidget
                                limit={12}
                                week={week}
                                currentFestival={currentFestival}
                            />
                        </div>

                    </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                    <div className="card" style={{ backgroundColor: '#68a1c3' }}>
                        <h5 className="card-header" style={{ backgroundColor: '#68a1c3', color: 'white', fontWeight: 'bold' }}>LH PHÁT BIỂU LỜI</h5>
                        <div className="card-body">
                            <HMAcademyDepartmentRankCupWidget
                                limit={10}
                                currentFestival={currentFestival}
                            />
                        </div>

                    </div>
                </div>
            </div>

            {
                //<div className="card" style={{ backgroundColor: '#68a1c3' }}>
                //    <h5 className="card-header" style={{ backgroundColor: '#68a1c3', color: 'white', fontWeight: 'bold' }}>LH RUNNING 7</h5>
                //    <div className="card-body">
                //        <div className="row">
                //            <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                //                <SwatTrundoWMTeamRankCupWidget
                //                    limit={12}
                //                />
                //            </div>
                //        </div>
                //    </div>

                //</div>
            }
        </>
    )
}